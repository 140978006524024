import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';

import Router, { RouterNavComponent } from '../../components/PageLocalNav';
import WIP from '../../components/WIP';
import { NewsDetails } from '../../components/News';
import store from '../../utils/store';

import Overview from './Overview';
import Financials from './Financials';
import VolumeDistribution from './PriceAnalysis/VolumeDistribution';
import SeasonalityChartTab from './PriceAnalysis/SeasonalityChartTab';
import HistoricalPriceTab from './PriceAnalysis/HistoricalPriceTab';
import PriceDownloadTab from './PriceAnalysis/PriceDownloadTab';
import QuoteMovementsTab from './PriceAnalysis/QuoteMovementsTab';
import TimeSalesTab from './PriceAnalysis/TimeSalesTab';
import TradeSummaryMatrixTab from './PriceAnalysis/TradeSummaryMatrixTab';
import EventsCalendar from './EventsCalendar';
import Shareholders from './Shareholders';
import DividendAnalysis from './DividendAnalysis';
import News from './News';
import InsiderTrades from './InsiderTrades';
import CompanyBuyback from './CompanyBuyback';
import Reports from './Reports';
import ConsensusEstimates from './ConsensusEstimates';
import CrowdsourceEstimates from './ConsensusEstimates/CrowdsourceEstimates';
import QuotePriceSummaryMini from '../../components/QuotePriceSummaryMini';
import './factsheet.css';

const REPORTS = {
  report_annual: 'Annual Report',
  report_summary: 'Summary Report',
  report_financial: 'Financial Report',
  report_summary_financial: 'Summary Financial Report',
  report_interim: 'Interim Report',
  report_summary_annual: 'Summary Annual Report',
  report_sustainability: 'Sustainability Report',
  report_quarterly: 'Quarterly Report',
  ipo_prospectus: 'IPO Prospectus',
  statement_disclosure: 'Disclosure Statement',
  statement_information: 'Information Statement',
  report_business_review: 'Business Review Report',
  financial_statements: 'Financial Statements',
};

const INSIDER_TRADES = {
  insider_latest: 'Latest Insider Trades',
  insider_significant_sales: 'Significant Sales',
  insider_significant_purchases: 'Significant Purchases',
  insider_search: 'Search',
};

function Factsheet({
  counter,
  url,
  tabs,
  tab,
  subTab,
  hasIntradayData,
  newsTabs,
  reportsTabs,
  priceDownload,
  priceSummary,
  isDuringStreamingHours,
  isPayingUser,
}) {
  const tabRef = useRef(null);

  // toggle factsheet nav on fixed table scroll
  const toggleFactsheetNav = (prevScrollY) => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    const factsheetNav = document.querySelector('.factsheetLocalNav');
    const factsheetSubNav = document.querySelector('.factsheetLocalSubNav');
    const fixedHeaderTableContainer = document.querySelector('.fixedHeaderTable');
    const fixedHeaderTable = document.querySelector('.scrollTableStickyHeaderContainer');
    const mainNav = document.querySelector('.navbar');

    const showNav = (nav) => {
      if (!nav) {
        return;
      }
      if (nav.classList.contains('d-none')) {
        nav.classList.remove('d-none');
      }
      if (!nav.classList.contains('d-flex')) {
        nav.classList.add('d-flex');
      }
    };

    const hideNav = (nav) => {
      if (!nav) {
        return;
      }
      if (nav.classList.contains('d-flex')) {
        nav.classList.remove('d-flex');
      }
      if (!nav.classList.contains('d-none')) {
        nav.classList.add('d-none');
      }
    };

    if (mainNav && factsheetNav && factsheetSubNav && fixedHeaderTable) {
      const threshold =
        fixedHeaderTableContainer.offsetTop -
        mainNav.offsetHeight -
        (window.scrollTop || document.documentElement.scrollTop);

      if (threshold <= fixedHeaderTable.offsetHeight) {
        hideNav(factsheetSubNav);

        // scroll down
        if (scrollY > prevScrollY && threshold <= 0) {
          hideNav(factsheetNav);
          fixedHeaderTable.style.top = `${mainNav.offsetHeight}px`;
          // scroll up
        } else {
          showNav(factsheetNav);
          fixedHeaderTable.style.top = `${mainNav.offsetHeight + factsheetNav.offsetHeight}px`;
        }
      } else {
        showNav(factsheetNav);
        showNav(factsheetSubNav);
      }
    } else {
      showNav(factsheetNav);
      showNav(factsheetSubNav);
    }
  };

  let selectedTab = tab;
  if (selectedTab == null || selectedTab.length === 0) {
    selectedTab = 'overview';
  }

  let newsChildren = [];
  if (newsTabs && Object.keys(newsTabs).length > 0) {
    newsChildren = Object.keys(newsTabs).map((tabKey) => ({
      key: tabKey,
      path: `${url}/news/${tabKey}`,
      label: newsTabs[tabKey],
      element: <News counter={counter} newsType={tabKey} basePath={`${url}/news`} />,
    }));

    const newsAllKey = Object.keys(newsTabs)[0];
    newsChildren.unshift({
      key: newsAllKey,
      index: true,
      path: `${url}/news`,
      label: newsTabs[newsAllKey],
      element: <News counter={counter} newsType={newsAllKey} basePath={`${url}/news`} />,
    });

    newsChildren.push({
      key: ':newsId',
      label: 'News Details',
      path: `${url}/news/:newsId`,
      element: <NewsDetails />,
      hideNav: true,
    });
  }

  let reportsChildren = [];
  const filteredReportsTab = Object.keys(REPORTS).filter((type) => reportsTabs.includes(type));
  if (filteredReportsTab && filteredReportsTab.length > 0) {
    reportsChildren = filteredReportsTab.map((type) => ({
      key: type,
      path: `${url}/reports/${type}`,
      label: REPORTS[type],
      element: <Reports counter={counter} type={type} />,
    }));

    const reportsDefaultType = filteredReportsTab[0];
    reportsChildren.unshift({
      key: reportsDefaultType,
      path: `${url}/reports`,
      index: true,
      label: REPORTS[reportsDefaultType],
      element: <Reports counter={counter} type={reportsDefaultType} />,
    });
  }

  const insiderTradesChildren = Object.keys(INSIDER_TRADES).map((type) => ({
    key: type,
    path: `${url}/insider_trades/${type}`,
    label: INSIDER_TRADES[type],
    element: <InsiderTrades counter={counter} tab={type} toggleFactsheetNav={toggleFactsheetNav} />,
  }));

  const insiderTradesDefaultType = Object.keys(INSIDER_TRADES)[0];
  insiderTradesChildren.unshift({
    key: insiderTradesDefaultType,
    path: `${url}/insider_trades`,
    index: true,
    label: INSIDER_TRADES[insiderTradesDefaultType],
    element: (
      <InsiderTrades
        counter={counter}
        tab={insiderTradesDefaultType}
        toggleFactsheetNav={toggleFactsheetNav}
      />
    ),
  });

  const priceAnalysisChildren = [
    {
      key: 'volume_distribution',
      index: true,
      path: `${url}/price_analysis`,
      label: 'Volume Distribution',
      element: <VolumeDistribution counter={counter} />,
    },
    {
      key: 'volume_distribution',
      path: `${url}/price_analysis/volume_distribution`,
      label: 'Volume Distribution',
      element: <VolumeDistribution counter={counter} />,
    },
    {
      key: 'seasonality_chart',
      path: `${url}/price_analysis/seasonality_chart`,
      label: 'Seasonality Chart',
      element: <SeasonalityChartTab counter={counter} />,
    },
    {
      key: 'historical_price',
      path: `${url}/price_analysis/historical_price`,
      label: 'Historical Price',
      element: <HistoricalPriceTab counter={counter} />,
    },
    {
      key: 'price_download',
      path: `${url}/price_analysis/price_download`,
      label: 'Price Download',
      element: <PriceDownloadTab counter={counter} priceDownloadConfig={priceDownload} />,
    },
    {
      key: 'trade_summary_matrix',
      path: `${url}/price_analysis/trade_summary_matrix`,
      label: 'Trade Summary Matrix',
      element: <TradeSummaryMatrixTab counter={counter} />,
    },
    {
      key: 'quote_movements',
      path: `${url}/price_analysis/quote_movements`,
      label: 'Quote Movements',
      element: <QuoteMovementsTab counter={counter} />,
    },
    {
      key: 'time_and_sales',
      path: `${url}/price_analysis/time_and_sales`,
      label: 'Time & Sales',
      element: <TimeSalesTab counter={counter} />,
    },
  ];

  const consensusEstimatesChildren = [
    {
      key: 'individual_company_estimates',
      index: true,
      path: `${url}/consensus_estimates`,
      label: 'Individual Company Estimates',
      element: <ConsensusEstimates counter={counter} />,
    },
    {
      key: 'individual_company_estimates',
      path: `${url}/consensus_estimates/individual_company_estimates`,
      label: 'Individual Company Estimates',
      element: <ConsensusEstimates counter={counter} />,
    },
    // {
    //   key: 'crowdsource_estimates',
    //   path: `${url}/consensus_estimates/crowdsource_estimates`,
    //   label: (
    //     <>
    //       <span className="me-2">Crowdsource Estimates</span>
    //       <span className="g-text-size-10 powered_by align-top me-1">Powered by</span>
    //       <img
    //         className="align-middle"
    //         src={require('../../../assets/images/logo_investingnote_estimates.png')}
    //         alt="InvestinNote"
    //         width="120"
    //         height="25"
    //       />
    //     </>
    //   ),
    //   element: <CrowdsourceEstimates counter={counter} tab={tab} />,
    // },
  ];

  const parentTabs = Object.keys(tabs).map((key) => ({
    key,
    path: `${url}/${key}`,
    label: tabs[key].label,
    icon: tabs[key].icon,
    class: tabs[key].class,
  }));

  const getUpdatedMeta = (key) => {
    if (!tabs[key].pageName && !tabs[key].metaDesc) {
      return null;
    }

    return (
      <Helmet>
        {tabs[key].pageName && <title>{tabs[key].pageName}</title>}
        {tabs[key].metaDesc && <meta name="og:title" content={tabs[key].pageName} />}
        {tabs[key].metaDesc && <meta name="description" content={tabs[key].metaDesc} />}
        {tabs[key].metaDesc && <meta name="og:description" content={tabs[key].metaDesc} />}
      </Helmet>
    );
  };

  const getChildren = (key) => {
    let children;
    switch (key) {
      case 'insider_trades':
        children = insiderTradesChildren;
        break;
      case 'price_analysis':
        children = priceAnalysisChildren;
        break;
      case 'news':
        children = newsChildren;
        break;
      case 'consensus_estimates':
        children = consensusEstimatesChildren;
        break;
      case 'reports':
        children = reportsChildren.length > 0 ? reportsChildren : null;
        break;
      default:
        children = null;
        break;
    }

    if (!children) {
      return null;
    }

    let selectedSubtab = children.filter((child) => child.key === subTab)[0];
    if (!selectedSubtab) {
      selectedSubtab = children[0];
    }

    const newTabs = children.map((child, index) => ({
      ...child,
      element: child.hideNav ? (
        child.element
      ) : (
        <RouterNavComponent
          classes="factsheetLocalSubNav p-1 sticky-top bg-white g-mb-40"
          navLinks={children}
          defaultActiveNav={child.key}
          content={
            <>
              {getUpdatedMeta(key)}
              {child.element}
            </>
          }
        />
      ),
    }));

    return newTabs;
  };

  const getComponent = (key) => {
    let content;
    switch (key) {
      case 'overview':
        content = (
          <Overview
            counter={counter}
            hasIntradayData={hasIntradayData}
            isPayingUser={isPayingUser}
          />
        );
        break;
      case 'financials':
        content = <Financials counter={counter} />;
        break;
      case 'events_calendar':
        content = <EventsCalendar counter={counter} />;
        break;
      case 'shareholders':
        content = <Shareholders counter={counter} />;
        break;
      case 'company_buyback':
        content = <CompanyBuyback counter={counter} />;
        break;
      case 'dividend_analysis':
        content = <DividendAnalysis counter={counter} />;
        break;
      case 'reports':
        content =
          reportsChildren.length > 0 ? null : <div className="text-center">No reports found.</div>;
        break;
      case 'price_analysis':
      case 'news':
      case 'insider_trades':
      case 'consensus_estimates':
        content = null;
        break;
      default:
        content = <WIP />;
        break;
    }

    if (content) {
      return (
        <>
          {getUpdatedMeta(key)}
          {content}
        </>
      );
    }

    return content;
  };

  const routes = [
    {
      path: `${url}`,
      element: (
        <div ref={tabRef}>
          <RouterNavComponent
            classes="factsheetLocalNav d-print-none g-mb-60 d-flex flex-row sticky-top bg-white"
            navLinks={parentTabs}
            defaultActiveNav={selectedTab}
            counterInfoComponent={
              <QuotePriceSummaryMini
                counter={counter}
                priceSummary={priceSummary}
                isDuringStreamingHours={isDuringStreamingHours}
              />
            }
          />
        </div>
      ),
      children: parentTabs.map((value) => ({
        ...value,
        element: getComponent(value.key),
        children: getChildren(value.key),
      })),
    },
  ];

  routes[0].children.unshift({
    ...routes[0].children[0],
    index: true,
    path: url,
  });

  useEffect(() => {
    const factsheetNav = document.querySelector('.factsheetLocalNav');
    const factsheetNavHeight = factsheetNav?.offsetHeight || 0;

    const pinHeader = () => {
      const tabContent = document.querySelector('.tab-content');
      const factsheetSubNav = document.querySelector('.factsheetLocalSubNav');
      const mainNav = document.querySelector('.navbar');
      const factsheetSubNavHeight = factsheetSubNav?.offsetHeight || 0;

      if (tabRef.current && tabContent && mainNav) {
        const navOffset = mainNav.offsetHeight + factsheetNavHeight + factsheetSubNavHeight;

        tabRef.current.classList.toggle(
          'headerPinned',
          tabContent.offsetTop - navOffset <
            (window.scrollTop || document.documentElement.scrollTop),
        );
      }
    };

    pinHeader();
    window.addEventListener('scroll', pinHeader);

    return () => {
      window.removeEventListener('scroll', pinHeader);
    };
  }, [tabRef]);

  return (
    <Provider store={store}>
      <Router routes={routes} activeNav={[tab, subTab]} />
    </Provider>
  );
}

Factsheet.propTypes = {
  counter: PropTypes.string,
  url: PropTypes.string,
  tabs: PropTypes.object,
  tab: PropTypes.string,
  subTab: PropTypes.string,
  hasIntradayData: PropTypes.bool,
  newsTabs: PropTypes.object,
  reportsTabs: PropTypes.array,
  priceSummary: PropTypes.object,
  isDuringStreamingHours: PropTypes.bool,
  priceDownload: PropTypes.shape({
    recentPriceDays: PropTypes.oneOfType([PropTypes.array]),
    hasIntradayData: PropTypes.bool,
    isSgMyMarket: PropTypes.bool,
  }),
  isPayingUser: PropTypes.bool,
};

export default Factsheet;
