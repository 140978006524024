import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSearchParams, useLocation } from 'react-router-dom';

import api from '../../../utils/api';
import { createLoadableComponent } from '../../../utils/Loadable';
import Pagination from '../../../components/Pagination';
import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';

const HistoricalPrice = createLoadableComponent(() => import('./HistoricalPrice'));
const { CancelToken } = axios;
const TYPE = 'daily';

function HistoricalPriceTab({ counter }) {
  const source = CancelToken.source();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState();
  const [type, setType] = useState(searchParams.get('historical_price_view') || TYPE);
  const [data, setData] = useState([]);
  const [page, setPage] = useState('1');
  const [pagination, setPagination] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [isSSR, setIsSSR] = useState(true);

  const handlePageChange = (newPage) => {
    if (!newPage || page === newPage) {
      return;
    }

    setSearchParams({ historical_price_view: type, page: newPage });
    setPage(newPage);
  };

  const handleTypeChange = (newType) => {
    if (type === newType) {
      return;
    }
    setSearchParams({ historical_price_view: newType, page: '1' });
    setType(newType);
    handlePageChange('1');
  };

  useEffect(() => {
    if (!type) {
      return;
    }

    const cancelToken = source.token;
    setIsLoading(true);

    let url = `quote/${counter}/historical_price.json?type=${type}`;
    if (page) {
      url += `&page=${page}`;
    }

    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        setData(data.data);
        setPagination(data.pagination);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, [type, page]);

  const HISTORICAL_TYPES = [
    {
      label: 'By Day',
      value: 'daily',
    },
    {
      label: 'By Week',
      value: 'weekly',
    },
    {
      label: 'By Month',
      value: 'monthly',
    },
    {
      label: 'By Year',
      value: 'yearly',
    },
  ];

  useEffect(() => {
    if (type !== searchParams.get('historical_price_view')) {
      setType(searchParams.get('historical_price_view') || TYPE);
    }
    if (page !== searchParams.get('page')) {
      setPage(searchParams.get('page') || '1');
    }
  }, [location]);

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');

    return () => {};
  }, []);

  if (isSSR) {
    return <Loader visible={isSSR} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  const HistoricalType = (
    <Dropdown className="g-mb-20">
      <Dropdown.Toggle variant="light">
        {HISTORICAL_TYPES.find((viewType) => viewType.value === type)?.label}
      </Dropdown.Toggle>
      <div className="d-flex gap-2 flex-wrap">
        <Dropdown.Menu>
          {HISTORICAL_TYPES.map((viewType) => {
            return (
              <Dropdown.Item
                key={viewType.value}
                onClick={() => handleTypeChange(viewType.value)}
                className={`${viewType.value === type ? 'active' : ''}`}>
                {viewType.label}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </div>
    </Dropdown>
  );

  return (
    <>
      <h4 className="g-md-mb-30">Historical Price</h4>
      {HistoricalType}
      <Loader visible={isLoading} />
      {!isLoading && data && data.historical_price && data.historical_price.length > 0 && (
        <>
          <HistoricalPrice data={data} />
          <Pagination
            rowOffset={pagination.page_row_offset}
            currentPageRows={data.historical_price.length}
            totalRows={pagination.total_rows}
            currentPage={Number(page)}
            totalPages={pagination.total_pages}
            handlePageChange={handlePageChange}
          />
          <div className="mt-5">
            <div className="g-text-size-14 g-fw-500 footer-disclaimer mb-3 g-mt-25">Notes:</div>
            <div className="sic_legendAlpha">
              <div className="d-flex gap-1 ">
                <sup className="footer-disclaimer">a</sup>
                <div className="g-text-size-14 footer-disclaimer">
                  Adjusted for corporate events to ensure the prices are always comparable across
                  different periods. These events include
                  <ul>
                    <li>Special Dividend</li>
                    <li>Dividend-In-Specie</li>
                    <li>Capital Reduction and Cash Distribution</li>
                    <li>Bonus Issue</li>
                    <li>Rights Issue / Preferential Offer / Open Offer</li>
                    <li>Share Split / Share Consolidation</li>
                  </ul>
                </div>
              </div>
              <div className="d-flex gap-1 ">
                <sup className="footer-disclaimer">b</sup>
                <div className="g-text-size-14 footer-disclaimer">
                  VWAP stands for Volume Weighted Average Price
                </div>
              </div>
              <div className="d-flex gap-1 ">
                <sup className="footer-disclaimer">c</sup>
                <div className="g-text-size-14 footer-disclaimer">
                  Short Sell Volume and Short Sell Value as reported by SGX daily from 11 March 2013
                </div>
              </div>
              <div className="d-flex gap-1 ">
                <sup className="footer-disclaimer">d</sup>
                <div className="g-text-size-14 footer-disclaimer">
                  Short Sell Percent is calculated as the percentage of Short Sell Volume to Volume
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!isLoading && (!data || data.historical_price.length === 0) && (
        <div className="text-center">No data found.</div>
      )}
    </>
  );
}

HistoricalPriceTab.propTypes = {
  counter: PropTypes.string,
};

export default HistoricalPriceTab;
