import React, { useRef, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';

function FixedHeaderTable({ header, body, tableContainerID, tableProps, handleScrollY }) {
  const sourceTableRef = useRef(null);
  const targetTableRef = useRef(null);
  const sourceContainerRef = useRef(null);
  const targetContainerRef = useRef(null);
  const mainNav = document.querySelector('.navbar');

  const handleScrollX = () => {
    document
      .querySelector(`#${tableContainerID}`)
      .querySelectorAll('table')
      .forEach((el) => {
        if (el.style.borderCollapse !== 'separate') {
          el.style.borderCollapse = 'separate';
        }
      });
  };

  const syncScroll = (source, target) => {
    handleScrollX();
    target.scrollLeft = source.scrollLeft;
  };

  const resizeHeader = (sourceTable, targetTable, sourceContainer) => {
    sourceTable
      .querySelector('thead')
      .querySelectorAll('tr')
      .forEach((e, i) => {
        e.querySelectorAll('th').forEach((el, index) => {
          const w = el.getBoundingClientRect().width;
          const h = el.getBoundingClientRect().height;
          const selector = targetTable
            .querySelector('thead')
            .querySelectorAll('tr')
            [i].querySelectorAll('th')[index];
          selector.style.minWidth = `${w}px`;
          selector.style.minHeight = `${h}px`;
        });
      });

    const targetTableHeight = targetTable.offsetHeight;
    sourceContainer.style.marginTop = `-${targetTableHeight}px`;
  };

  useEffect(() => {
    const sourceTable = sourceTableRef.current;
    const targetTable = targetTableRef.current;
    const sourceContainer = sourceContainerRef.current;
    const targetContainer = targetContainerRef.current;
    let prevScrollY = window.scrollY || document.documentElement.scrollTop;

    const handleWindowScroll = () => {
      if (handleScrollY) {
        handleScrollY(prevScrollY);
      }
      prevScrollY = window.scrollY || document.documentElement.scrollTop;
    };

    const throttleWindowScrollHandler = throttle(handleWindowScroll, 100);

    if (sourceTable && targetTable) {
      resizeHeader(sourceTable, targetTable, sourceContainer);
      sourceContainer.addEventListener('scroll', () => {
        syncScroll(sourceContainer, targetContainer);
      });
      targetContainer.addEventListener('scroll', () => {
        syncScroll(targetContainer, sourceContainer);
      });
      sourceTable.addEventListener('resize', () => {
        resizeHeader(sourceTable, targetTable, sourceContainer);
      });
      window.addEventListener('resize', () => {
        resizeHeader(sourceTable, targetTable, sourceContainer);
      });

      if (handleScrollY) {
        window.addEventListener('scroll', throttleWindowScrollHandler);
      }
    }

    return () => {
      sourceContainer.removeEventListener('scroll', () => {
        syncScroll(sourceContainer, targetContainer);
      });
      targetContainer.removeEventListener('scroll', () => {
        syncScroll(targetContainer, sourceContainer);
      });
      sourceTable.removeEventListener('resize', () => {
        resizeHeader(sourceTable, targetTable, sourceContainer);
      });
      window.removeEventListener('resize', () => {
        resizeHeader(sourceTable, targetTable, sourceContainer);
      });
      window.removeEventListener('scroll', throttleWindowScrollHandler);
    };
  }, []);

  useEffect(() => {
    const sourceTable = sourceTableRef.current;
    const targetTable = targetTableRef.current;
    const sourceContainer = sourceContainerRef.current;
    resizeHeader(sourceTable, targetTable, sourceContainer);
  }, [header, body]);

  return (
    <div className="fixedHeaderTable" {...(tableContainerID ? { id: tableContainerID } : {})}>
      <div
        className="sticky-top scrollTableStickyHeaderContainer d-print-none"
        style={{
          top: `${mainNav ? mainNav.offsetHeight : 65}px`,
        }}
        ref={targetContainerRef}>
        <Table
          hover
          className={
            tableProps && tableProps.className
              ? tableProps.className
              : 'table-with-hover-popup text-center align-middle m-0'
          }
          ref={targetTableRef}>
          {header}
        </Table>
      </div>
      <div className="table-responsive" ref={sourceContainerRef}>
        <Table
          hover
          className="table-with-hover-popup text-center align-middle"
          {...tableProps}
          ref={sourceTableRef}>
          {header}
          {body}
        </Table>
      </div>
    </div>
  );
}

FixedHeaderTable.propTypes = {
  header: PropTypes.element,
  body: PropTypes.element,
  tableProps: PropTypes.oneOfType([PropTypes.object]),
  tableContainerID: PropTypes.string,
  handleScrollY: PropTypes.func,
};

export default FixedHeaderTable;
