import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Tab, Nav } from 'react-bootstrap';

import Notes from './Notes';
import api from '../../../utils/api';
import APIErrorHandler from '../../../components/APIErrorHandler';
import Loader from '../../../components/Loader';
import CompanyBuyBackChart from './CompanyBuyBackChart';
import CompanyLatestBuybackTable from '../../../components/CompanyLatestBuybackTable';

const { CancelToken } = axios;

function CompanyBuyback({ counter }) {
  const source = CancelToken.source();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [isSSR, setIsSSR] = useState(true);
  const market = counter.split(':')[0].toLowerCase();

  const analysisTabs = {
    trades: 'Number of Trades',
    volume: 'Transaction Volume',
    value: 'Transaction Value',
  };

  const [activeAnalysisTab, setActiveAnalysisTab] = useState('trades');
  const handleAnalysisTabClick = (tab) => {
    setActiveAnalysisTab(tab);
  };

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    const cancelToken = source.token;
    setIsLoading(true);

    api
      .get(`quote/${counter}/company_buyback.json?`, { cancelToken })
      .then(({ data }) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, []);

  if (isSSR || isLoading) {
    return <Loader visible={isSSR || isLoading} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <div className="row">
      {data && data.success && (
        <div>
          {data.buyback_analysis_data && (
            <>
              <h4>
                Analysis Of Company Buyback For Last {data.buyback_analysis_data.years_available}{' '}
                Calendar Year{data.buyback_analysis_data.years_available > 1 ? 's' : ''}
              </h4>
              <Tab.Container defaultActiveKey={activeAnalysisTab}>
                <div className="g-mb-50">
                  <Nav variant="tabs" className="design-3 g-mb-20">
                    {Object.keys(analysisTabs).map((tab) => {
                      return (
                        <Nav.Item key={tab}>
                          <Nav.Link
                            className={`nav-link  ${activeAnalysisTab === tab ? 'active' : ''}`}
                            eventKey={tab}
                            id={tab}
                            onClick={() => handleAnalysisTabClick(tab)}>
                            {analysisTabs[tab]}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                  <div className="">
                    {data.buyback_analysis_data && (
                      <Tab.Content>
                        {Object.keys(analysisTabs).map((tab) => {
                          const analysisType = data.buyback_analysis_data[tab] || null;
                          return analysisType ? (
                            <Tab.Pane id={tab} key={tab} eventKey={tab}>
                              <div className="table-responsive">
                                <Table className="text-center">
                                  <thead className="table-light">
                                    <tr>
                                      <th className="g-per-width-25">&nbsp;</th>
                                      <th className="g-per-width-15">Q1 (Jan to Mar)</th>
                                      <th className="g-per-width-15">Q2 (Apr to Jun)</th>
                                      <th className="g-per-width-15">Q3 (Jul to Sep)</th>
                                      <th className="g-per-width-15">Q4 (Oct to Dec)</th>
                                      <th className="g-per-width-15">Total</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Object.keys(analysisType)
                                      .reverse()
                                      .map((year) => (
                                        <tr key={year}>
                                          <td>{year}</td>
                                          {analysisType[year].map((col, index) => (
                                            <td
                                              key={`${year}_${index}`}
                                              className={!col ? 'td-blank' : ''}>
                                              {col}
                                            </td>
                                          ))}
                                        </tr>
                                      ))}
                                  </tbody>
                                </Table>
                              </div>
                            </Tab.Pane>
                          ) : null;
                        })}
                      </Tab.Content>
                    )}
                  </div>
                </div>
              </Tab.Container>
            </>
          )}

          {data.buyback_chart_markers && (
            <CompanyBuyBackChart counter={counter} buybackChartData={data.buyback_chart_markers} />
          )}

          {data.buyback_transactions_data && (
            <>
              <h4>Company Buyback Transaction</h4>
              <div className="table-responsive">
                <CompanyLatestBuybackTable data={data.buyback_transactions_data} market={market} />
              </div>
              <Notes />
            </>
          )}
        </div>
      )}
      {(!data || !data.success) && (
        <div className="col-12 text-center">
          No Company Buyback Information Available For This Counter.
        </div>
      )}
    </div>
  );
}

CompanyBuyback.propTypes = {
  counter: PropTypes.string,
};

export default CompanyBuyback;
