import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function Note({ country }) {
  const plan = useSelector((state) => state.membership.plan);
  const marketType = useSelector((state) => state.membership.marketType);
  const adminNumber = country === 'sg' ? '+65 6958 8000' : '+60 3 8681 0580';
  const adminEmail = country === 'sg' ? 'admin@shareinvestor.com' : 'accounts.my@shareinvestor.com';

  return (
    <div className="text-muted g-mb-40 g-text-size-14">
      <div className="mb-2">
        <strong>Note:</strong>
      </div>

      {!plan.includes('station') && (
        <div className="d-flex gap-1 ms-4">
          <span className="footer-disclaimer">
            <sup>1</sup>
          </span>
          <div className="footer-disclaimer">
            If you are an existing Pro plan subscriber and would like to add an optional feature to
            your existing membership, please{' '}
            <strong>
              <a href="https://classic.shareinvestor.com/membership/add_options">click here</a>
            </strong>
            .
          </div>
        </div>
      )}

      {plan.includes('station') && (
        <>
          <div className="d-flex gap-1 ms-4">
            <span className="footer-disclaimer">
              <sup>1</sup>
            </span>
            <div className="footer-disclaimer">
              For upgrade of membership plan, the top-up fee will differ according to the expiry
              date of your existing membership plan. The actual fee will be reflected upon clicking
              the "Next" button.
            </div>
          </div>
          <div className="d-flex gap-1 ms-4">
            <span className="footer-disclaimer">
              <sup>2</sup>
            </span>
            <div className="footer-disclaimer">
              If you are an existing Station subscriber and would like to add an optional feature to
              your existing membership, please contact us at <strong>{adminNumber}</strong> or{' '}
              <strong>
                <a href={`mailto:${adminEmail}`}>{adminEmail}</a>
              </strong>{' '}
              and we will advise you on the procedure accordingly.
            </div>
          </div>
          <div className="d-flex gap-1 ms-4">
            <span className="footer-disclaimer">
              <sup>3</sup>
            </span>
            <div className="footer-disclaimer">
              ShareInvestor reserves the rights, at our sole discretion, to add or remove part or
              all the revamped FTSE/ST indices without any prior notice.
            </div>
          </div>
        </>
      )}

      {marketType !== 'local' && (
        <div className="d-flex gap-1 ms-4">
          <span className="footer-disclaimer">
            <sup>{!plan.includes('station') ? '2' : '4'}</sup>
          </span>
          <div className="footer-disclaimer">
            Estimated delayed for HKEx is 15 minutes but could go up to 2 hours during periods of
            heavy transactions.
          </div>
        </div>
      )}
    </div>
  );
}

export default Note;

Note.propTypes = {
  country: PropTypes.string,
};
