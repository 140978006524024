import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import { membershipChoosePlan, membershipSetMarketType } from '../membershipSlice';

function MarketDataGlobalSelect({ country }) {
  const dispatch = useDispatch();
  const marketType = useSelector((state) => state.membership.marketType);
  const plan = useSelector((state) => state.membership.plan);

  const handleMarketTypeChange = (newMarketType) => {
    if (marketType === newMarketType) {
      return;
    }

    dispatch(membershipSetMarketType(newMarketType));
    const newPlan =
      newMarketType === 'global'
        ? plan.replace('_local', '')
        : plan.includes('_local')
        ? plan
        : `${plan}_local`;
    dispatch(membershipChoosePlan(newPlan));
  };

  if (country.toLowerCase() !== 'my') {
    return null;
  }

  return (
    <div className="d-flex text-center">
      <Card
        className={`d-inline-block my-2 p-2 shadow-sm g-per-width-48 ${
          marketType === 'global' ? 'border-success border-3' : ''
        }`}
        onClick={() => {
          handleMarketTypeChange('global');
        }}
        type="button">
        <Card.Body className="text-center">
          <span className="d-inline-block g-mr-10">
            <h4 className="my-3">Malaysia + Global Market</h4>
          </span>
        </Card.Body>
      </Card>

      <Card
        className={`d-inline-block my-2 p-2 shadow-sm g-per-width-48 ms-2 ${
          marketType === 'local' ? 'border-success border-3' : ''
        }`}
        onClick={() => {
          handleMarketTypeChange('local');
        }}
        type="button">
        <Card.Body className="text-center">
          <span className="d-inline-block g-mr-10">
            <h4 className="my-3">Malaysia Only</h4>
          </span>
        </Card.Body>
      </Card>
    </div>
  );
}

export default MarketDataGlobalSelect;

MarketDataGlobalSelect.propTypes = {
  country: PropTypes.string,
};
