import React, { useLayoutEffect } from 'react';

import FactsheetCollapseTable from '../../../components/FactsheetCollapseTable';
import { setupColumnLineChart } from './setupColumnLineChart';
import classes from './DividendAnalysis.module.css';

function PriceEarningsRatioAgainstDividendYield({ data, isREIT }) {
  if (!(data && Object.keys(data).length > 0)) {
    return null;
  }

  const searchRegExp = /\[%\]/g;
  const replaceWith = '(%)';
  const chartData = {
    seriesConfig: [],
    data: [],
    leftYlabel: 'PE Ratio',
    rightYlabel: 'Dividend Yield',
  };
  Object.entries(data).map(([key, val]) => {
    const title = `${val.title.replace(searchRegExp, replaceWith)} ${val.postfix}`;
    const isDivYield = title.toLocaleLowerCase().includes('yield');
    const isSpecialDiv = title.toLocaleLowerCase().includes('special');

    if (isREIT && isSpecialDiv) {
      return null;
    }

    chartData.seriesConfig.push({
      id: key,
      seriesTitle: title,
      seriesType: isDivYield ? 'line' : 'column',
      stacked: isDivYield,
      hidden: isSpecialDiv,
    });

    val.values.map((x) => {
      const yearLabel = `${x.period_f} ${x.date_time_f}`;
      const dataIdx = chartData.data.findIndex((d) => d.year === yearLabel);
      if (dataIdx > -1) {
        // update/append data
        chartData.data[dataIdx][key] = x.value ? Number(x.value) : null;
      } else {
        // insert new data
        const toInsert = {};
        toInsert.year = yearLabel;
        toInsert[key] = x.value ? Number(x.value) : null;
        chartData.data.push(toInsert);
      }
      return null;
    });
    return null;
  });

  useLayoutEffect(() => {
    const chart = setupColumnLineChart('stockPriceEarningsRatioAgainstDDChart', chartData);

    return () => {
      chart.dispose();
    };
  }, []);

  // TableRow data
  const tableData = Object.entries(data).map(([k, v]) => {
    return v;
  });

  return (
    <div className="g-pb-30">
      <h4>{`Price Earnings Ratio against ${isREIT ? 'Distribution' : 'Dividend'} Yield`}</h4>
      <div id="stockPriceEarningsRatioAgainstDDChart" className={classes.chartContainer} />
      <FactsheetCollapseTable tableData={tableData} tableID="dividend_analysis_table_perady" />
    </div>
  );
}

export default PriceEarningsRatioAgainstDividendYield;
