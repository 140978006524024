import React from 'react';
import PropTypes from 'prop-types';

import { isNegative, isPositive } from '../../utils/utils';
import classes from './worldIndicesPrices.module.css';

const EOD_WLD_INDICES = {
  set: 'SET.BK',
  sse: '000001.WLD',
  n225: 'N225.WLD',
  kospi: 'KOSPI.WLD',
};

export default function CarouselItem({ label, code, lastDone, changeVal, percChange }) {
  const itemLabel = label?.toUpperCase().trim() || '';
  let percClass = '';
  if (Number(changeVal) < 0) {
    percClass = 'text-danger';
  } else if (Number(changeVal) > 0) {
    percClass = 'text-success';
  }

  const indexChartBaseUrl = '//chart.shareinvestor.com/charts_cached/charts.pl';
  return (
    <div className={`stock-card g-ml-8 g-mr-8 ${classes.cardContainer}`}>
      <div className="row g-0">
        <div className="col">
          <div className="fw-bold content">{itemLabel}</div>
          <div id={`sic_${itemLabel}_lastDone`} className="lastDone content">
            {lastDone || '-'}
          </div>
          <div id={`sic_${itemLabel}_change`} className={`change ${percClass}`}>
            <span id={`sic_${itemLabel}_changeValue`} className="changeValue content">
              {changeVal || '-'}
            </span>
            <span id={`sic_${itemLabel}_percentageChange`} className="percentageChange content">
              {percChange ||
                (isNegative(changeVal) ? '(-0.00%)' : isPositive(changeVal) ? '(+0.00%)' : '')}
            </span>
          </div>
        </div>
        <div className="col">
          <img
            id={`sic_${itemLabel}_indexChart`}
            className="h-100 img-fluid"
            src={`${indexChartBaseUrl}?type=si_index_mini_plain&id=${code}`}
            alt="Chart"
          />
          {(Object.values(EOD_WLD_INDICES).includes(code) ||
            Object.keys(EOD_WLD_INDICES).includes(label?.toLowerCase())) && (
            <span className={`${classes.historical_data_tooltip} hover_popup_container`}>
              <i className="fa-solid fa-clock-rotate-left fa-sm text-muted" />
              <div
                className={`${classes.historical_data_tooltip_content} hover_popup_content g-border-radius-10 border p-1`}>
                <div className="hover_popup_content_inner g-text-size-13">End of Day Data</div>
              </div>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

CarouselItem.propTypes = {
  label: PropTypes.string,
  code: PropTypes.string,
  lastDone: PropTypes.string,
  changeVal: PropTypes.string,
  percChange: PropTypes.string,
};
