import React from 'react';
import { Provider } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import store from '../../../utils/store';
import StocksComparisonMain from './StocksComparisonMain';

function StocksComparison({ code = [], type = 'stocks' }) {
  return (
    <Provider store={store}>
      <Container fluid>
        <Row>
          <Col>
            <div className="g-mb-20">
              <StocksComparisonMain code={code} type={type} />
            </div>
          </Col>
        </Row>
      </Container>
    </Provider>
  );
}

StocksComparison.propTypes = {
  code: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string
};


export default StocksComparison;
