import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';

import { fetchData, setYearsToShow, setPage } from './dividendAnalysisSlice';
import { fetchDefinition } from '../../../components/DefinitionPopup/definitionSlice';
import EarningAgainstDividend from './EarningAgainstDividend';
import PriceEarningsRatioAgainstDividendYield from './PriceEarningsRatioAgainstDividendYield';
import PriceEarningsRatioAgainstDividendPayoutRatio from './PriceEarningsRatioAgainstDividendPayoutRatio';
import DividendPayoutHistoryTable from './DividendPayoutHistoryTable';
import DividendPayoutChart from './DividendPayoutChart';
import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';
import { createLoadableComponent } from '../../../utils/Loadable';

const { CancelToken } = axios;

const checkDataIsEmpty = (data) => {
  if (!data) {
    return false;
  }

  return Object.keys(data).every((key, index, arr) => {
    if (!Array.isArray(data[key])) {
      return Object.keys(data[key]).length === 0;
    }

    return data[key].length === 0;
  });
};
const Filter = createLoadableComponent(() => import('./Filter'));
export default function DividendAnalysis({ counter }) {
  if (!counter) {
    return null;
  }
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const yearsToShow = useSelector((state) => state.dividendAnalysis.yearsToShow);
  const data = useSelector((state) => state.dividendAnalysis.data);
  const page = useSelector((state) => state.dividendAnalysis.page);
  const completed = useSelector((state) => state.dividendAnalysis.completed);
  const loading = useSelector((state) => state.dividendAnalysis.loading);
  const error = useSelector((state) => state.dividendAnalysis.err);
  const [isSSR, setIsSSR] = useState(true);
  const isEmptyData = useMemo(() => checkDataIsEmpty(data?.data), [data]);
  const location = useLocation();

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    const newYearsToShow = searchParams.get('years_to_show');
    const newPage = searchParams.get('page');
    dispatch(fetchDefinition({ cancelToken: source.token, source: 'dividend_analysis' }));
    dispatch(setYearsToShow(newYearsToShow));
    dispatch(setPage(newPage));
    dispatch(
      fetchData({
        filters: { counter, yearsToShow: newYearsToShow, page: newPage },
        cancelToken: source.token,
      }),
    );
  }, []);

  useEffect(() => {
    if (data && data.years_to_show && yearsToShow !== data.years_to_show) {
      dispatch(setYearsToShow(data.years_to_show.toString()));
    }
    if (data && data.pagination && page !== data.pagination.current_page) {
      dispatch(setPage(data.pagination.current_page.toString()));
    }
  }, [data]);

  useEffect(() => {
    const newYearsToShow = searchParams.get('years_to_show');
    const newPage = searchParams.get('page');
    let reloadData = false;

    if (newYearsToShow && yearsToShow !== newYearsToShow) {
      dispatch(setYearsToShow(newYearsToShow));
      reloadData = true;
    }
    if (newPage && page !== newPage) {
      dispatch(setPage(newPage));
      reloadData = true;
    }
    if (reloadData) {
      dispatch(
        fetchData({
          filters: {
            counter,
            yearsToShow: newYearsToShow,
            page: newPage,
          },
          cancelToken: source.token,
        }),
      );
    }
  }, [location]);

  if (isSSR || loading) {
    return <Loader visible={isSSR || loading} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  if (!(completed && data && data.data) || (completed && isEmptyData)) {
    return (
      <div className="d-flex justify-content-center w-100 g-height-1000">
        <h6 className="mt-5">{`- Sorry, there is no dividend declared by this company${
          data && data.stock_name ? `: ${data.stock_name}` : '.'
        }. -`}</h6>
      </div>
    );
  }

  return (
    <>
      <Filter counter={counter} />
      <div id="sic_dividendAnalysis" className="g-mt-30">
        <EarningAgainstDividend
          data={data.data.earnings_against_dividend}
          isREIT={data.stock_status?.is_reit}
        />
        <PriceEarningsRatioAgainstDividendYield
          data={data.data.price_earnings_ratio_against_dividend_yield}
          isREIT={data.stock_status?.is_reit}
        />
        {!data.stock_status?.is_reit && (
          <PriceEarningsRatioAgainstDividendPayoutRatio
            data={data.data.price_earnings_ratio_against_dividend_yield_ratio}
            isREIT={false}
          />
        )}
        <DividendPayoutChart
          counter={counter}
          isREIT={data.stock_status?.is_reit}
          dividendData={data.data.dividend_payout_chart_markers}
        />
        <DividendPayoutHistoryTable
          data={data.data.dividend_payout_history}
          isREIT={data.stock_status?.is_reit}
          currency={data.currency}
        />
      </div>
    </>
  );
}

DividendAnalysis.propTypes = {
  counter: PropTypes.string,
};
