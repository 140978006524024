import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import { fetchData, fetchAllCounterLists } from './companyBuybackLatestSlice';
import Loader from '../../../../components/Loader';

import Pagination from '../../../../components/Pagination';
import MarketSelectDropdown from '../../../../components/MarketSelectDropdown';
import Notes from './Notes';
import APIErrorHandler from '../../../../components/APIErrorHandler';
import { createLoadableComponent } from '../../../../utils/Loadable';
import CompanyLatestBuybackTable from '../../../../components/CompanyLatestBuybackTable';

const DateFilter = createLoadableComponent(() => import('../../../../components/DateFilter'));

const { CancelToken } = axios;

function BuybackLatest({ market, availableMarkets, buybackLatestMinStartDate }) {
  const dispatch = useDispatch();
  const [isSSR, setIsSSR] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const source = CancelToken.source();
  const data = useSelector((state) => state.marketsCompanyBuybackLatest.data);
  const allCounterLists = useSelector((state) => state.marketsCompanyBuybackLatest.allCounterLists);
  const loadingCounterList = useSelector(
    (state) => state.marketsCompanyBuybackLatest.loadingCounterList,
  );
  const completed = useSelector((state) => state.marketsCompanyBuybackLatest.completed);
  const error = useSelector((state) => state.marketsCompanyBuybackLatest.err);
  const loading = useSelector((state) => state.marketsCompanyBuybackLatest.loading);
  const defaultMarket = useSelector((state) => state.selectedMarket.selectedMarket);
  const [selectedMarket, setSelectedMarket] = useState(market || defaultMarket);
  const pagination = useSelector((state) => state.marketsCompanyBuybackLatest.pagination);
  const [page, setPage] = useState(searchParams.get('page') || '1');
  const [selectedCounter, setSelectedCounter] = useState(searchParams.get('counter'));
  const [counterListOptions, setCounterListOptions] = useState(null);
  const [fromDate, setFromDate] = useState(searchParams.get('search_start_date') || null);
  const [toDate, setToDate] = useState(searchParams.get('search_end_date') || null);
  const navigate = useNavigate();
  const location = useLocation();

  const getCurrentParams = () => {
    const params = {};
    if (selectedCounter) {
      params.counter = selectedCounter;
    }
    if (fromDate) {
      params.search_start_date = fromDate;
    }
    if (toDate) {
      params.search_end_date = toDate;
    }
    if (page) {
      params.page = page;
    }

    return params;
  };

  const handleOnChangeSelectedCounter = (newCounter) => {
    if (newCounter && newCounter.value === selectedCounter) {
      return;
    }

    // allow counter to be null, clean counter params
    let counter = null;
    if (!newCounter) {
      if (searchParams.has('counter')) {
        searchParams.delete('counter');
        setSearchParams(searchParams);
      }
    } else {
      counter = newCounter.value;
      setSearchParams({ ...getCurrentParams(), counter: `${counter}` });
    }

    setSelectedCounter(counter);
    handlePageChange('1');
  };

  const handleOnChangeFromDate = (_newDate) => {
    if (!_newDate) {
      return;
    }

    let newDate = '';
    if (_newDate.length > 0) {
      newDate = dayjs(new Date(_newDate)).format('YYYY-MM-DD');
    }

    if (fromDate === newDate) {
      return;
    }

    setSearchParams({ ...getCurrentParams(), search_start_date: newDate });
    setFromDate(newDate);
    handlePageChange('1');
  };

  const handleOnChangeToDate = (_newDate) => {
    if (!_newDate) {
      return;
    }

    let newDate = '';
    if (_newDate.length > 0) {
      newDate = dayjs(new Date(_newDate)).format('YYYY-MM-DD');
    }

    if (toDate === newDate) {
      return;
    }

    setSearchParams({ ...getCurrentParams(), search_end_date: newDate });
    setToDate(newDate);
    handlePageChange('1');
  };

  const handleDateReset = () => {
    if (searchParams.has('search_start_date')) {
      searchParams.delete('search_start_date');
    }
    if (searchParams.has('search_end_date')) {
      searchParams.delete('search_end_date');
    }
    setSearchParams(searchParams);
    setToDate('');
    setFromDate('');
  };

  const handlePageChange = (newPage) => {
    if (!newPage || page === newPage) {
      return;
    }

    setSearchParams({ ...getCurrentParams(), page: newPage });
    setPage(newPage);
  };

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    dispatch(
      fetchData({
        market: selectedMarket,
        counter: selectedCounter,
        fromDate,
        toDate,
        page,
        cancelToken: source.token,
      }),
    );

    return () => {};
  }, [selectedMarket, selectedCounter, page, fromDate, toDate]);

  // Get counter filter dropdown data
  useEffect(() => {
    if (!allCounterLists || !(allCounterLists && allCounterLists.length)) {
      return;
    }
    const options = allCounterLists.map(({ name, counter }) => ({
      value: counter,
      label: name,
    }));
    setCounterListOptions(options);
  }, [allCounterLists]);

  // update selectedMarket state when selectedMarket redux has changed.
  useEffect(() => {
    if (defaultMarket == null) {
      return;
    }
    setCounterListOptions(null);
    dispatch(
      fetchAllCounterLists({
        market: defaultMarket,
        cancelToken: source.token,
      }),
    );

    if (defaultMarket === selectedMarket.toLowerCase()) {
      return;
    }
    setSelectedMarket(defaultMarket);
    // Reset all filter state when market change
    setSelectedCounter(null);
    setFromDate(null);
    setToDate(null);
    handlePageChange('1');
    navigate(`/${defaultMarket.toUpperCase()}/company_buyback/buyback_latest`);
  }, [defaultMarket]);

  useEffect(() => {
    if (selectedCounter !== searchParams.get('counter')) {
      setSelectedCounter(searchParams.get('counter'));
    }
    if (
      searchParams.get('search_start_date') &&
      fromDate !== searchParams.get('search_start_date')
    ) {
      setFromDate(searchParams.get('search_start_date'));
    }
    if (searchParams.get('search_end_date') && toDate !== searchParams.get('search_end_date')) {
      setToDate(searchParams.get('search_end_date'));
    }
    if (page !== searchParams.get('page')) {
      setPage(searchParams.get('page') || '1');
    }
  }, [location]);

  if (isSSR) {
    return <Loader visible={isSSR} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <>
      <div className="g-mb-30 d-flex flex-column flex-sm-row gap-3">
        <MarketSelectDropdown
          market={selectedMarket}
          variant="light"
          validMarkets={availableMarkets}
          updateUrlOnClick
        />
        {loadingCounterList && (
          <span>
            <Loader visible={loadingCounterList} />
          </span>
        )}
        {!loadingCounterList && counterListOptions && counterListOptions.length > 0 && (
          <div className="d-flex flex-column flex-sm-row gap-3">
            <div>
              <Select
                unstyled
                isClearable
                backspaceRemovesValue
                // defaultMenuIsOpen
                placeholder="Search Counter"
                className="g-width-250 react-select-container"
                classNamePrefix="react-select"
                value={counterListOptions.filter((option) => option.value === selectedCounter)}
                onChange={handleOnChangeSelectedCounter}
                options={counterListOptions}
              />
            </div>
            <div className="g-border-l-0 g-sm-border-l-1 g-pl-0 g-sm-pl-15">
              <DateFilter
                isInterval
                minFromDate={dayjs(new Date(buybackLatestMinStartDate)).format('YYYY-MM-DD')}
                fromDate={fromDate}
                toDate={toDate}
                handleFromDateChange={handleOnChangeFromDate}
                handleToDateChange={handleOnChangeToDate}
                handleReset={handleDateReset}
              />
            </div>
          </div>
        )}
      </div>
      <Loader visible={loading} classes="g-height-800" />
      {data && data.buyback_stocks && data.buyback_stocks.length > 0 && (
        <>
          <h5>Company Buyback Transactions</h5>
          <div className="table-responsive g-mb-50">
            <CompanyLatestBuybackTable
              data={data.buyback_stocks}
              market={selectedMarket}
              linkToFactsheet
            />
            <Pagination
              rowOffset={pagination.page_row_offset}
              currentPageRows={data.buyback_stocks.length}
              totalRows={pagination.total_rows}
              currentPage={Number(page)}
              totalPages={pagination.total_pages}
              handlePageChange={handlePageChange}
            />
          </div>
        </>
      )}

      <Notes />
    </>
  );
}

BuybackLatest.propTypes = {
  market: PropTypes.string,
  availableMarkets: PropTypes.oneOfType([PropTypes.array]),
  buybackLatestMinStartDate: PropTypes.string,
};

export default BuybackLatest;
