import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, OverlayTrigger, Tooltip, Form, InputGroup, Alert } from 'react-bootstrap';
import debounce from 'lodash/debounce';

import {
  getFeaturePrice,
  getLocalCurrency,
  calculateTotalPrice,
  calculateGST,
  displayGSTPercentage,
  calculatePriceWithGST,
  formatPrice,
  getFeatureDisplayName,
  calculateLoyaltyDisount,
  getPlanID,
  getAddOnsID,
  getFilteredFeatures,
} from './prices';

import {
  membershipSetPromoCode,
  applyCouponCode,
  membershipSetCouponDiscAmt,
  processMembership,
  membershipCouponReset,
} from './membershipSlice';
import classes from './membership.module.css';

const { CancelToken } = axios;

function MembershipSummary({ country, handleNextClick, nextLabel, nextDisabled }) {
  const source = CancelToken.source();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.membership.data);
  const plan = useSelector((state) => state.membership.plan);
  const period = useSelector((state) => state.membership.period);
  const addOns = useSelector((state) => state.membership.addOns);
  const globalPromoCode = useSelector((state) => state.membership.promoCode);
  const marketType = useSelector((state) => state.membership.marketType);
  const priceType = useSelector((state) => state.membership.priceType);
  const coupon = useSelector((state) => state.membership.coupon);
  const couponDiscAmt = useSelector((state) => state.membership.couponDiscAmt);
  const loading = useSelector((state) => state.membership.loading);
  const invoice = useSelector((state) => state.membership.invoice);
  const [planName, setPlanName] = useState();
  const [promoCode, setPromoCode] = useState(globalPromoCode);
  const [databaseUpdated, setdatabaseUpdated] = useState(true);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const countryName = country === 'sg' ? 'Singapore' : 'Malaysia';
  const showSubtotal =
    (priceType || !plan?.includes('station')) && (marketType || country === 'sg');

  const getSubtotal = (withDiscount = true) => {
    if (!showSubtotal) {
      return 0;
    }

    const formattedAddOns = getFilteredFeatures(data, plan, period, addOns, priceType);
    const loyaltyDiscount = withDiscount ? data.loyalty_discount : 0;
    const couponDisc = withDiscount ? couponDiscAmt : 0;

    return calculateTotalPrice(data, plan, period, formattedAddOns, loyaltyDiscount, couponDisc);
  };

  const [subtotal, setSubtotal] = useState(getSubtotal(false));
  const [total, setTotal] = useState(calculatePriceWithGST(country, getSubtotal(true)));

  const handlePromoCodeChange = (event) => {
    const newPromoCode = event.target.value;
    if (newPromoCode === promoCode) {
      return;
    }

    if (!newPromoCode || newPromoCode.length === 0) {
      dispatch(membershipCouponReset());
    }

    setPromoCode(newPromoCode);
  };

  const handleApplyPromoCode = debounce(async () => {
    if (promoCode && promoCode.length > 0) {
      const filteredAddons = getFilteredFeatures(data, plan, period, addOns, priceType);
      await dispatch(
        applyCouponCode({
          couponCode: promoCode,
          plan: getPlanID(data, plan, period),
          addOns: getAddOnsID(data, plan, period, filteredAddons),
          cancelToken: source.token,
        }),
      );
    } else {
      await dispatch(membershipCouponReset());
    }
  }, 500);

  const handleSubmit = () => {
    if (promoCode && promoCode.length > 0) {
      dispatch(membershipSetPromoCode(promoCode));
      handleApplyPromoCode();
    }

    handleNextClick();
  };

  const calculateBenefits = (benefit) => {
    if (benefit.applied_discount) {
      return parseFloat(benefit.applied_discount);
    }
    return 0;
  };

  const updateDatabase = () => {
    const filteredAddons = getFilteredFeatures(data, plan, period, addOns, priceType);
    dispatch(
      processMembership({
        plan: getPlanID(data, plan, period),
        period,
        addOns: getAddOnsID(data, plan, period, filteredAddons),
        payment: 'visa',
        couponCode: promoCode,
        cancelToken: source.token,
      }),
    );
  };

  const discountTooltip = () => {
    return (
      <Tooltip className={`${classes.tooltipCustom} tooltip_custom`}>
        <div className="text-start overflow-x-hidden overflow-y-scroll p-2">
          <table className="table table-borderless mb-0">
            <tbody>
              {country === 'sg' && (
                <tr>
                  <td>
                    <div className="g-mb-10">
                      Loyalty Discount<sup>*</sup> ({data?.loyalty_discount || 0}%)
                    </div>
                    {data?.loyalty_discount && parseInt(data.loyalty_discount) > 0 && (
                      <div>
                        ({getLocalCurrency(country)}
                        {getFeaturePrice(data, plan, period, 'platform_fee')}) X{' '}
                        {data?.loyalty_discount || 0}%
                      </div>
                    )}
                  </td>
                  <td className="text-end align-bottom">
                    {data?.loyalty_discount && parseInt(data.loyalty_discount) > 0
                      ? `${getLocalCurrency(country)}${formatPrice(
                          calculateLoyaltyDisount(data, plan, period, data.loyalty_discount),
                        )}`
                      : 'N.A.'}
                  </td>
                </tr>
              )}
              {coupon?.benefits &&
                coupon.benefits.map((benefit, i) => (
                  <tr key={i}>
                    {calculateBenefits(benefit) > 0 && (
                      <>
                        <td>Coupon Discount</td>
                        <td className="text-end">
                          {getLocalCurrency(country)}
                          {formatPrice(calculateBenefits(benefit))}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              <tr className="border-top border-dark">
                <th>Total Discount</th>
                <th className="text-end">
                  {getLocalCurrency(country)}
                  {formatPrice(totalDiscount)}
                </th>
              </tr>
            </tbody>
          </table>

          {country === 'sg' && (
            <small className="text-muted g-text-size-12">
              <sup>*</sup> Loyalty Discount applicable to Platform Fee only
            </small>
          )}
        </div>
      </Tooltip>
    );
  };

  useEffect(() => {
    setSubtotal(getSubtotal(false));
  }, [data, plan, period, addOns, couponDiscAmt, priceType]);

  useEffect(() => {
    setTotal(calculatePriceWithGST(country, getSubtotal(true)));
  }, [data, plan, period, addOns, couponDiscAmt, priceType]);

  useEffect(() => {
    let discount = 0;

    if (data?.loyalty_discount && parseInt(data.loyalty_discount) > 0) {
      discount += calculateLoyaltyDisount(data, plan, period, data.loyalty_discount);
    }

    if (coupon?.benefits) {
      discount += couponDiscAmt;
    }

    if (discount === totalDiscount) {
      return;
    }

    setTotalDiscount(discount);
  }, [data, plan, period, addOns, couponDiscAmt, priceType]);

  useEffect(() => {
    let discount = 0;
    if (coupon && coupon.benefits) {
      discount = coupon.benefits.reduce((accumulator, benefit) => {
        return accumulator + calculateBenefits(benefit);
      }, 0);
    }

    dispatch(membershipSetCouponDiscAmt(discount));
    dispatch(membershipSetPromoCode(promoCode));
    setdatabaseUpdated(false);
  }, [coupon]);

  useEffect(() => {
    const newPlanName = `${plan.includes('station') ? 'Premium' : 'Pro'} Plan`;
    if (plan && newPlanName !== planName) {
      setPlanName(newPlanName);
    }
  }, [plan]);

  useEffect(() => {
    if (!databaseUpdated) {
      updateDatabase();
      setdatabaseUpdated(true);
    }
    return () => {};
  }, [couponDiscAmt]);

  return (
    <>
      {invoice && !invoice.success && (
        <Alert variant="danger">
          <p>
            <strong>Error encountered:</strong> {invoice.error}
          </p>
        </Alert>
      )}
      <div className={classes.stickyHeader}>
        {data?.membership && period && (
          <Card className="g-max-width-500 mx-auto shadow my-3">
            <Card.Body className="p-4">
              <table className="table table-borderless">
                <tbody>
                  {showSubtotal && (
                    <tr className="border-bottom">
                      <td width="70%">
                        {plan.includes('_local')
                          ? `${countryName} Market`
                          : `${countryName} + Global Market`}
                      </td>
                      <td className="text-end">
                        {getLocalCurrency(country)}
                        {formatPrice(getFeaturePrice(data, plan, period, 'data_fee'))}
                      </td>
                    </tr>
                  )}

                  {getFilteredFeatures(data, plan, period, addOns, priceType).map((addOnKey) => (
                    <tr className="border-bottom" key={addOnKey}>
                      <td>{getFeatureDisplayName(addOnKey, priceType)}</td>
                      <td className="text-end">
                        {getLocalCurrency(country)}
                        {formatPrice(getFeaturePrice(data, plan, period, addOnKey))}
                      </td>
                    </tr>
                  ))}

                  {showSubtotal && (
                    <tr className="border-bottom">
                      <td width="70%">
                        {planName} - {period === 6 ? 'Half yearly' : 'Annually'}
                      </td>
                      <td className="text-end">
                        {getLocalCurrency(country)}
                        {formatPrice(getFeaturePrice(data, plan, period, 'platform_fee'))}
                      </td>
                    </tr>
                  )}

                  <tr className="border-bottom">
                    <th>Subtotal</th>
                    <th className="text-end">
                      {getLocalCurrency(country)}
                      {formatPrice(subtotal)}
                    </th>
                  </tr>

                  {showSubtotal && (
                    <>
                      <tr>
                        <td>
                          <span className="d-inline-block">Discount</span>
                          <OverlayTrigger placement="auto" overlay={discountTooltip()}>
                            <span className="d-inline-block g-ml-10">
                              <i className="fa-solid fa-circle-info" />
                            </span>
                          </OverlayTrigger>
                        </td>
                        <th className="text-end">
                          {getLocalCurrency(country)}
                          {formatPrice(totalDiscount)}
                        </th>
                      </tr>
                      <tr className="border-bottom">
                        <td className="pt-0" colSpan={2}>
                          <InputGroup>
                            <Form.Control
                              type="text"
                              onChange={handlePromoCodeChange}
                              value={promoCode}
                              placeholder="Promo code"
                              className="form-control"
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') handleApplyPromoCode();
                              }}
                              disabled={loading}
                            />
                            <Button
                              variant="outline-dark g-min-width-50"
                              onClick={handleApplyPromoCode}
                              disabled={loading}>
                              Apply
                            </Button>
                          </InputGroup>

                          {promoCode && coupon?.error && (
                            <small className="text-danger ms-2">{coupon?.error}</small>
                          )}

                          {promoCode && coupon?.success && (
                            <small className="text-primary ms-2">
                              Coupon code <strong>{promoCode}</strong> applied.
                            </small>
                          )}
                        </td>
                      </tr>
                    </>
                  )}

                  <tr className="border-bottom border-dark">
                    <td>
                      {country === 'my' ? 'SST' : 'GST'} ({displayGSTPercentage(country)}%)
                    </td>
                    <th className="text-end">
                      {getLocalCurrency(country)}
                      {formatPrice(calculateGST(country, getSubtotal(true)))}
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <h5>Total</h5>
                    </td>
                    <th className="text-end">
                      <h5>
                        {getLocalCurrency(country)}
                        {formatPrice(total)}
                      </h5>
                    </th>
                  </tr>
                </tbody>
              </table>
              {handleNextClick && (
                <Button className="w-100 p-2" onClick={handleSubmit} disabled={nextDisabled}>
                  {nextLabel || 'Next'}
                </Button>
              )}
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  );
}

export default MembershipSummary;

MembershipSummary.propTypes = {
  country: PropTypes.string,
  handleNextClick: PropTypes.func,
  nextLabel: PropTypes.string,
  nextDisabled: PropTypes.bool,
};
