/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import CompanyNameLink from '../StockInfoPopup/CompanyNameLink';
import LayoutOptions from './LayoutOptions';
import LastUpdatedAt from './LastUpdatedAt';
import TextSearchFilter from './TextSearchFilter';
import Pagination from '../Pagination';
import { getParamCounterId } from '../../utils/utils';
import { pricesAddSelectedStocks, pricesRemoveSelectedStocks } from './priceTableSlice';
import FixedHeaderTable from '../FixedHeaderTable';

import { createLoadableComponent } from '../../utils/Loadable';

export { LayoutOptions, LastUpdatedAt };

const StockInfoPopup = createLoadableComponent(() => import('../StockInfoPopup'));

async function stopStreaming() {
  const { stopPricesStreaming } = await import('../../utils/Streaming/prices_table');
  stopPricesStreaming();
}

async function startStreaming() {
  const { startPricesStreaming } = await import('../../utils/Streaming/prices_table');
  startPricesStreaming();
}

const SPARKLINE_CHART_HEADER = '3 Months Historical';
const TABLE_HEADER_ATTR = {
  Select: {
    field: 'itemCompare',
    fieldClass: 'sic_compare_stocks_checkbox',
    cclass: 'sic_compare_stocks_checkbox',
    cscope: 'col',
  },
  No: {
    field: 'itemPos',
    fieldClass: 'sic_no',
    cclass: 'sic_no',
    cscope: 'col',
  },
  Name: {
    field: 'short_name',
    fieldClass: 'sic_companyName',
    cclass: 'sic_companyName',
    cscope: 'col',
  },
  'Last Done': {
    field: 'price',
    fieldClass: 'sic_highlight',
    cclass: '',
    cscope: 'col',
  },
  Chg: {
    field: 'change',
    fieldClass: '',
    cclass: '',
    cscope: 'col',
  },
  '% Chg': {
    field: 'perc_change',
    fieldClass: '',
    cclass: '',
    cscope: 'col',
  },
  SparklineChart: {
    field: 'sparklineChart',
    fieldClass: 'sparkline',
    cclass: 'sparkline',
    cscope: 'col',
  },
  Vol: {
    field: 'volume',
    fieldClass: '',
    cclass: '',
    cscope: 'col',
  },
  'Buy Vol': {
    field: 'buy_vol',
    fieldClass: '',
    cclass: '',
    cscope: 'col',
  },
  Buy: {
    field: 'buy',
    fieldClass: 'sic_highlight',
    cclass: '',
    cscope: 'col',
  },
  Sell: {
    field: 'sell',
    fieldClass: 'sic_highlight',
    cclass: '',
    cscope: 'col',
  },
  'Sell Vol': {
    field: 'sell_vol',
    fieldClass: '',
    cclass: '',
    cscope: 'col',
  },
  High: {
    field: 'high',
    fieldClass: '',
    cclass: '',
    cscope: 'col',
  },
  Low: {
    field: 'low',
    fieldClass: '',
    cclass: '',
    cscope: 'col',
  },
  BLot: {
    field: 'board_lot',
    fieldClass: '',
    cclass: '',
    cscope: 'col',
  },
  Rem: {
    field: 'status',
    fieldClass: '',
    cclass: 'sic_rem si_center',
    cscope: 'col',
  },
  'Open Price': {
    field: 'open',
    fieldClass: '',
    cclass: '',
    cscope: 'col',
  },
  'Yesterday Close': {
    field: 'reference',
    fieldClass: '',
    cclass: '',
    cscope: 'col',
  },
  action: {
    field: 'action',
    fieldClass: 'action',
    cclass: 'action',
    cscope: 'col',
  },
};

function tableHeaders(market, tab) {
  if (!market && !tab) {
    return Object.keys(TABLE_HEADER_ATTR).filter(
      (h) => !['BLot', 'Rem', 'Open Price', 'Yesterday Close'].includes(h),
    );
  }
  const hdrExclusions = [];
  if (!market || market === 'world') {
    hdrExclusions.push('Vol', 'Rem');
  }
  if (!tab || tab === 'indices' || tab === 'forex' || tab === 'futures') {
    hdrExclusions.push('Buy Vol', 'Buy', 'Sell', 'Sell Vol', 'BLot');
  }
  if (market && market !== 'world' && tab && !['indices', 'forex', 'futures'].includes(tab)) {
    hdrExclusions.push('Open Price', 'Yesterday Close');
  }
  return Object.keys(TABLE_HEADER_ATTR).filter((h) => !hdrExclusions.includes(h));
}

function cellClass(header, value) {
  if (['Chg', '% Chg', 'P/L', '% P/L', 'PL + Div'].includes(header)) {
    const fltVal = parseFloat(value?.replaceAll(',', '')) || 0;
    return fltVal > 0 ? 'sic_up' : fltVal < 0 ? 'sic_down' : '';
  }

  if (header === SPARKLINE_CHART_HEADER) {
    return TABLE_HEADER_ATTR.SparklineChart.cclass;
  }

  if (TABLE_HEADER_ATTR[header] && TABLE_HEADER_ATTR[header].cclass) {
    return TABLE_HEADER_ATTR[header].cclass;
  }

  return '';
}

export default function PriceTable({
  data,
  filters,
  streamingChannel,
  isDuringStreamingHours,
  searchFilter,
  pagination,
  handleChangePagination,
  actionColumns,
  actionTriggered,
  isModeStreaming,
  streamingType,
  folder,
  isPortfolio,
  enableSparklineChart,
}) {
  if (!(data && filters)) {
    return null;
  }

  const { tab, filter, type, layout, market, page, embededFilter } = filters;
  const [searchKey, setSearchKey] = useState(searchFilter);
  const streamingLayout = layout === 'trading_data';
  const shouldStream =
    isDuringStreamingHours && streamingLayout && market !== 'bursa_derivative' && tab !== 'forex';
  const pageRowOffset = pagination ? pagination.page_row_offset : 0;
  const currentPageRowsCount = data.length;

  const getHeaders = () => {
    const exclusions = ['SparklineChartHeader'];
    let headers = streamingLayout ? tableHeaders(market, tab) : Object.keys(data[0]);
    if (isPortfolio) {
      exclusions.push('Select');
    }
    if (headers.includes('No') && headers.includes('counter')) {
      exclusions.push('counter');
    }
    if (!headers.includes('No') && !headers.includes('counter')) {
      headers.splice(0, 0, 'No');
    }
    if (!actionColumns) {
      exclusions.push('action');
    }
    if (actionColumns && !headers.includes('action')) {
      headers.push('action');
    }
    if (!enableSparklineChart) {
      exclusions.push('SparklineChart');
    }
    headers = headers.filter((h) => !exclusions.includes(h));
    return headers;
  };

  const tableProps = {
    id: 'sic_pricesTable',
    'data-feed_mode': streamingLayout && isModeStreaming && shouldStream ? 'streaming' : 'static',
    'data-tbl_layout': layout,
    'data-streaming-type': streamingType,
    'data-tbl_row_offset':
      pagination && pagination.page_row_offset ? pagination.page_row_offset : 0,
    className: `table table-border table-hover text-end m-0 p-0 layout-${layout}`,
  };

  if (market) tableProps['data-market'] = market;
  if (streamingLayout && streamingChannel) tableProps['data-streaming_channel'] = streamingChannel;
  if (filter) tableProps['data-filter_code'] = filter;
  if (type) tableProps['data-filter_type'] = type;
  if (page) tableProps['data-tbl_page_num'] = page;
  if (tab) tableProps['data-tbl_tab'] = tab;
  if (folder) tableProps['data-tbl_folder'] = folder;

  const headers = getHeaders();
  const actionButtonTriggered = (e, action_, rowOrigCounter, rowName) => {
    let counter = rowOrigCounter;
    let counterName = rowName;
    let action = action_;

    const nearestRow = e.target.closest('tr');
    const stockPopupHover = nearestRow?.querySelector('.stock_popup_hover');
    const name = stockPopupHover?.dataset.name;

    if (stockPopupHover?.dataset.counter && stockPopupHover.dataset.counter !== counter) {
      counter = stockPopupHover.dataset.counter;
      counterName = name && name.length > 0 ? name : getParamCounterId(counter);
    }

    if (!action) {
      action = e.target.closest('span')?.dataset.action;
    }

    actionTriggered(counter, counterName, action);
  };

  const getColumns = () => {
    const columns = headers.map((h) => {
      const result = {
        id: h,
        accessor: h,
        disableFilters: true,
        disableSortBy: streamingLayout,
      };

      if (h === 'counter' || h === 'No') {
        result.Header = 'No';
        result.Cell = (row) => pageRowOffset + row.index + 1;
      } else if (h === 'action') {
        result.Header = actionColumns.header;
        result.Cell = function (row) {
          return actionColumns.actions.map((action) => {
            const rowOrigCounter = row.counter;
            const customTooltipLabel = action.tooltipLabelFunc
              ? action.tooltipLabelFunc(action.action, rowOrigCounter)
              : '';
            return (
              <OverlayTrigger
                placement="top"
                key={`${action.action}_${rowOrigCounter}`}
                overlay={<Tooltip>{customTooltipLabel || action.label}</Tooltip>}>
                <span
                  role="button"
                  className={`g-ml-5 g-mr-5 icon actionButton-${action.action}`}
                  data-action={action.action}
                  onClick={(e) =>
                    actionButtonTriggered(e, action.action, rowOrigCounter, row.Name)
                  }>
                  {action.icon}
                </span>
              </OverlayTrigger>
            );
          });
        };
      } else if (h === 'SparklineChart') {
        result.Header = data?.at(0)?.SparklineChartHeader || SPARKLINE_CHART_HEADER;
        result.Cell = function (row) {
          return (
            <div className="h-100 w-100 d-flex flex-wrap align-items-center">
              <img className="w-100" src={row.SparklineChart} />
            </div>
          );
        };
      } else if (embededFilter && embededFilter.length > 0 && embededFilter.includes(h)) {
        result.Header = h.replaceAll('<br>', ' ');
        result.Filter = function (column) {
          return <TextSearchFilter column={column} />;
        };
        result.disableSortBy = true; // stop sorting when clicking on the filter
        result.disableFilters = false;
        result.setFilter = setSearchKey;
        result.filterValue = searchKey;
      } else {
        result.Header = h.replaceAll('<br>', ' ');
      }

      return result;
    });
    return columns;
  };

  // Listen for input changes outside
  useEffect(() => {
    setSearchKey(searchFilter);
  }, [searchFilter]);

  useEffect(() => {
    const pricesTable = document.getElementById('sic_pricesTable');
    if (
      pricesTable &&
      streamingLayout &&
      isModeStreaming &&
      shouldStream &&
      typeof document !== 'undefined'
    ) {
      startStreaming();
    }

    const currentTable = document.querySelector(`#${tableProps.id}`);
    if (currentTable) {
      currentTable.addEventListener('streamingTableCopiedActions', () => {
        document.querySelectorAll('.copiedActions span').forEach((element) => {
          element.addEventListener('click', actionButtonTriggered);
        });
      });
    }

    return () => {
      if (currentTable) {
        currentTable.removeEventListener('streamingTableCopiedActions', () => {
          document.querySelectorAll('.copiedActions span').forEach((element) => {
            element.removeEventListener('click', actionButtonTriggered);
          });
        });
      }

      if (
        pricesTable &&
        streamingLayout &&
        isModeStreaming &&
        shouldStream &&
        typeof document !== 'undefined'
      ) {
        stopStreaming();
      }
    };
  }, []);

  return (
    <>
      <Table
        data={data}
        tableProps={tableProps}
        columns={getColumns()}
        searchFilter={searchKey}
        enableSorting={!streamingLayout}
        pageRowOffset={pageRowOffset}
      />
      {!pagination && handleChangePagination && (
        <div
          id="stock_prices_pagination"
          className="pagination d-flex bg-gray-100 g-pl-20 g-pr-20 justify-content-between">
          <span className=" py-3">{`1-${currentPageRowsCount} of ${currentPageRowsCount} results`}</span>
        </div>
      )}
      {pagination && Object.keys(pagination) && Object.keys(pagination).length > 0 && (
        <Pagination
          rowOffset={pageRowOffset}
          currentPageRows={currentPageRowsCount}
          totalRows={pagination.total_rows}
          currentPage={Number(filters.page)}
          totalPages={pagination.total_pages}
          handlePageChange={handleChangePagination}
        />
      )}
      <StockInfoPopup />
    </>
  );
}

function Table({ data, columns, tableProps, searchFilter, enableSorting, pageRowOffset }) {
  const [sorting, setSorting] = useState({});
  const baseData = [...data].map((item, index) => {
    return { ...item, index };
  });
  const [filteredData, setFilteredData] = useState(baseData);

  const sort = (column) => {
    if (!enableSorting) {
      return;
    }

    const newSort = {
      header: column.Header === 'No' ? 'index' : column.accessor,
      isSortedDesc:
        sorting.header === column.accessor || (sorting.header === 'index' && column.Header === 'No')
          ? !sorting.isSortedDesc
          : false,
    };

    setSorting(newSort);
  };

  const listSorting = (a, b) => {
    const valA = a[sorting.header];
    const valB = b[sorting.header];

    // Helper function to convert numeric-like strings (with commas) to numbers
    const toNumber = (val) => {
      if (typeof val === 'string') {
        // Remove commas for thousand separators and try to parse
        const sanitizedVal = val.replace(/,/g, '');
        return isNaN(sanitizedVal) ? null : parseFloat(sanitizedVal);
      }
      return typeof val === 'number' ? val : null;
    };

    const numA = toNumber(valA);
    const numB = toNumber(valB);

    // Check if both values are numbers (including sanitized numeric strings)
    if (numA !== null && numB !== null) {
      return sorting.isSortedDesc ? numB - numA : numA - numB;
    }

    // Check if both values are valid dates
    const dateA = new Date(valA);
    const dateB = new Date(valB);
    if (!isNaN(dateA) && !isNaN(dateB)) {
      return sorting.isSortedDesc ? dateB - dateA : dateA - dateB;
    }

    // Otherwise, treat as strings and use localeCompare for alphabetical order
    return sorting.isSortedDesc
      ? String(valB).localeCompare(String(valA))
      : String(valA).localeCompare(String(valB));
  };

  useEffect(() => {
    const sortedData = baseData.sort(listSorting);
    if (!searchFilter || searchFilter.length === 0) {
      setFilteredData(sortedData);
      return;
    }

    if (columns.filter((c) => c.id === 'Name')[0]) {
      setFilteredData(
        sortedData.filter(
          (d) =>
            d.Name.toLowerCase().includes(searchFilter.toLowerCase()) ||
            (d.counter && d.counter.toLowerCase().includes(searchFilter.toLowerCase())),
        ),
      );
    } else if (columns.filter((c) => c.id === 'Code')[0]) {
      setFilteredData(
        sortedData.filter((d) => d.Code.toLowerCase().includes(searchFilter.toLowerCase())),
      );
    }
  }, [searchFilter]);

  useEffect(() => {
    if (!sorting.header || sorting.header.length === 0) {
      return;
    }

    setFilteredData([...filteredData].sort(listSorting));
  }, [sorting]);

  return (
    <FixedHeaderTable
      header={<TableHeader columns={columns} sorting={sorting} sort={sort} />}
      body={
        <TableBody
          rows={filteredData}
          columns={columns}
          tableID={tableProps.id}
          pageRowOffset={pageRowOffset}
        />
      }
      tableContainerID={`dt-${tableProps.id}`}
      tableProps={tableProps}
    />
  );
}

function TableHeader({ columns, sorting, sort }) {
  return (
    <thead className="table-light">
      <tr className="sic_tableTopRow">
        {columns.map((column) => {
          const headerAttr = TABLE_HEADER_ATTR[column.accessor];
          return (
            <th
              field={headerAttr && headerAttr.field}
              field_class={headerAttr && headerAttr.fieldClass}
              className={`${!column.disableSortBy ? 'sortable' : ''} ${
                headerAttr && headerAttr.cclass ? headerAttr.cclass : ''
              }`}
              scope={headerAttr && headerAttr.cscope}
              key={column.Header}
              onClick={() => !column.disableSortBy && sort(column)}>
              {column.Header === 'Select'
                ? ''
                : column.disableFilters
                ? column.Header
                : column.Filter(column)}
              {!column.disableSortBy && (
                <span className="ms-2">
                  {(sorting.header === column.accessor ||
                    (sorting.header === 'index' && column.Header === 'No')) &&
                    sorting.isSortedDesc && (
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                        <path d="M320 240L160 384 0 240l0-48 320 0 0 48z" />
                      </svg>
                    )}
                  {(sorting.header === column.accessor ||
                    (sorting.header === 'index' && column.Header === 'No')) &&
                    !sorting.isSortedDesc && (
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                        <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
                      </svg>
                    )}
                </span>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

function TableBody({ rows, tableID, columns }) {
  const dispatch = useDispatch();

  // Handle when checkbox is checked or unchecked
  const selectStockHandler = (event) => {
    if (event.target.checked) {
      const { isStock } = event.target.dataset;
      dispatch(pricesAddSelectedStocks({ code: event.target.value, isStock }));
    } else {
      dispatch(pricesRemoveSelectedStocks(event.target.value));
    }
  };

  return (
    <tbody>
      {rows.map((row, i) => {
        return (
          <tr id={`${tableID}_${i}`} key={row.counter}>
            {columns.map((column) => {
              const cclass = cellClass(column.Header, row[column.accessor]);
              return (
                <td
                  className={cclass || (column.accessor === 'action' && 'action')}
                  key={column.Header}>
                  {cclass?.includes('sic_companyName') && row[column.accessor] && (
                    <CompanyNameLink
                      counter={getParamCounterId(row.counter)}
                      name={row.Name}
                      showStockInfoPopUp
                      showCounter
                    />
                  )}
                  {!['Select', 'Open Price', 'Yesterday Close', 'Is Stock', 'Chg', 'P/L'].includes(
                    column.Header,
                  ) &&
                    !cclass?.includes('sic_companyName') &&
                    (column.Cell ? column.Cell(row) : row[column.accessor])}
                  {column.Header === 'Select' && (
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          name="counter_select[]"
                          value={row.counter}
                          onChange={selectStockHandler}
                          data-is-stock={row['Is Stock']}
                        />
                      </label>
                    </div>
                  )}
                  {column.Header === 'Open Price' && row.Open}
                  {column.Header === 'Yesterday Close' && row.Previous}
                  {(column.Header === 'Chg' || column.Header === 'P/L') && (
                    <span className="d-inline-block">{row[column.Header]}</span>
                  )}
                  {column.Header === 'Is Stock' && (
                    <div className="g-mr-12">{row['Is Stock'] ? 'Yes' : 'No'}</div>
                  )}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
}
