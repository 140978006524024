import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import store from '../../utils/store';
import MarketSelectDropdown from '../../components/MarketSelectDropdown';
import StockPricesAnalysisTools from './StockPricesAnalysisTools';
import Title from '../../components/Title';
import PageTitle from './PageTitle';
import ActionButtons from './ActionButtons';

import WorldIndicesPrices from '../../components/WorldIndicesPrices';
import StockPriceTable from './StockPriceTable';

async function stopStreaming() {
  const { forceStopStreaming } = await import('../../utils/Streaming');
  forceStopStreaming();
}

const SELECT_MARKET_OPTIONS = [
  'sgx',
  'bursa',
  'hkex',
  'set',
  'idx',
  'asx',
  'nyse',
  'nasdaq',
  'nyse_mkt',
  'world',
];

function StockPrices(props) {
  if (!props) {
    return null;
  }

  useEffect(() => {
    return () => {
      stopStreaming();
    };
  }, []);

  return (
    <Provider store={store}>
      <div className="g-min-height-160">
        <WorldIndicesPrices defaultIndices={props.defaultIndices} />
      </div>
      <Title titleComponent={<PageTitle />} />
      <div className="row justify-content-xl-between">
        <div className="col-xl-4 g-mb-15 d-flex gap-2">
          <MarketSelectDropdown market={props.market} validMarkets={SELECT_MARKET_OPTIONS} />
          <StockPricesAnalysisTools />
        </div>
        {/* TODO: ADD backend after IFSG */}
        <div className="col-xl-8 d-flex flex-wrap justify-content-xl-end gap-2 g-mb-15">
          <ActionButtons />
        </div>
      </div>
      <StockPriceTable props={props} />
    </Provider>
  );
}

StockPrices.propTypes = {
  url: PropTypes.string,
  tab: PropTypes.string,
  filter: PropTypes.string,
  type: PropTypes.string,
  layout: PropTypes.string,
  page: PropTypes.string,
  market: PropTypes.string,
};

export default StockPrices;
