import React, { useEffect, useState, useDeferredValue } from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DOMPurify from 'dompurify';

import SparklineChart from '../SparklineChart';
import FinancialMiniBar from '../FinancialMiniBar';
import DefinitionPopup from '../DefinitionPopup';

import classes from './FactsheetCollapseTableRows.module.css';

function estimateHover(data = {}) {
  if (data) {
    let results = '';
    results += `Highest: ${data.high}<br/>`;
    results += `Average: ${data.mean}<br/>`;
    results += `Lowest: ${data.low}`;
    return results;
  }
  return '';
}

// valuesLength only useful when it is subtitle (refer to financial's financial ratios)
function FactsheetCollapseTableRow({
  rowData,
  showAll,
  showSparklines,
  valuesLength,
  isConsensus,
}) {
  if (Object.keys(rowData).length === 0) {
    return null;
  }
  const [showMiniBar, setMiniBar] = useState(false);
  const [expandRow, setExpandRow] = useState(showAll || false);
  const [sparklineData, setSparklineData] = useState([]);
  const deferredSparklineData = useDeferredValue(sparklineData);
  const isIndentTitle = rowData?.title_indent_space > 0;
  const isSectionTitle = rowData?.is_sectionTitle === true;
  const showGrowth = rowData?.show_growth === undefined ? true : rowData.show_growth;
  const colSpan = valuesLength + (showSparklines ? 2 : 1);

  useEffect(() => {
    if (rowData?.values?.length > 0 && !isSectionTitle && showSparklines) {
      setSparklineData(
        rowData.values.map((period) => ({
          name: period.date_time_f,
          value: period.value,
        })),
      );
    }
  }, [rowData]);

  // update initialShowAll to false when showAll is true and expandRow is false
  useEffect(() => {
    if (showAll !== expandRow) {
      setExpandRow(showAll);
    }
  }, [showAll]);

  const handleExpandRow = () => {
    setExpandRow((_expandRow) => !_expandRow);
  };

  return (
    <>
      <tr className={classes[`${rowData.class_tr}`]}>
        {!isSectionTitle && (
          <th className="text-start g-fw-500 g-text-size-15 align-middle">
            <div className={isIndentTitle ? 'g-pl-20 g-lg-pl-40' : ''}>
              <div className="d-flex flex-row">
                {showGrowth && (
                  <span
                    role="button"
                    className="sic_financialsShowHidGrowth"
                    onClick={handleExpandRow}>
                    {expandRow && (
                      <span className="g-lg-text-size-15 g-text-size-13">
                        <i className="fa-thin fa-circle-minus fa-xl g-mr-5 g-lg-mr-10" />
                      </span>
                    )}
                    {!expandRow && (
                      <span className="g-lg-text-size-15 g-text-size-13">
                        <i className="fa-thin fa-circle-plus fa-xl g-mr-5 g-lg-mr-10" />
                      </span>
                    )}
                  </span>
                )}

                <DefinitionPopup keyword={rowData.definition} delayShow={500} delayHide={1000}>
                  <span className={!isConsensus ? (!showGrowth ? 'g-lg-pl-30 g-pl-24' : '') : ''}>
                    <span>{rowData.title}</span>
                    <span>
                      {rowData.postfix && <i className="g-ml-3 g-fw-300">{rowData.postfix}</i>}
                    </span>
                    <span
                      className="g-ml-3 g-fw-300"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(rowData.title_unit ? rowData.title_unit : ''),
                      }}
                    />
                  </span>
                </DefinitionPopup>
              </div>
              <div>
                {rowData.subtitle && (
                  <div
                    className={`g-text-size-13 g-lg-text-size-14 g-fw-400 ${
                      !isConsensus ? (showGrowth ? 'g-pl-30' : 'g-lg-pl-30 g-pl-24') : ''
                    }`}>
                    {rowData.subtitle}
                  </div>
                )}
              </div>
            </div>
          </th>
        )}
        {isSectionTitle && (
          <th
            colSpan={colSpan}
            aria-label="subtitle"
            className="text-start bg-gray-200 align-middle">
            {rowData.title}
            {rowData.subtitle && <p>{rowData.subtitle}</p>}
          </th>
        )}
        {!isSectionTitle && showSparklines && (
          <td className="align-middle">
            {deferredSparklineData === sparklineData && (
              <>
                <SparklineChart
                  data={deferredSparklineData}
                  displayMiniBar={() => setMiniBar(true)}
                />
                {showMiniBar && (
                  <FinancialMiniBar
                    showMiniBar={showMiniBar}
                    handleClose={() => setMiniBar(false)}
                  />
                )}
              </>
            )}
          </td>
        )}
        {rowData.values.map((col, index) => {
          return (
            <td
              key={col.date_time_f}
              className={`text-center align-middle ${col.is_estimates ? 'bg-gray-200' : ''}`}>
              {col.is_estimates ? (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            estimateHover({ mean: col.mean, high: col.high, low: col.low }),
                          ),
                        }}
                      />
                    </Tooltip>
                  }>
                  <div
                    className={
                      col.value_f === 'n.a.' || (!col.value_f && col.value === 'n.a.')
                        ? 'sic_gray'
                        : ''
                    }>
                    {col.value_f || col.value}
                    {col.financial_remarks_legend && <sup>{col.financial_remarks_legend}</sup>}
                  </div>
                </OverlayTrigger>
              ) : (
                <div
                  className={
                    col.value_f === 'n.a.' || (!col.value_f && col.value === 'n.a.')
                      ? 'sic_gray'
                      : ''
                  }>
                  {col.value_f || col.value}
                  {col.financial_remarks_legend && <sup>{col.financial_remarks_legend}</sup>}
                </div>
              )}
            </td>
          );
        })}
      </tr>
      {!isSectionTitle && showGrowth && (
        <tr
          className={
            expandRow ? classes.sic_financialsGrowthShown : classes.sic_financialsGrowthHidden
          }>
          <td className="text-start">
            <div
              className={`g-text-size-14 ${
                isIndentTitle ? 'g-pl-44 g-lg-pl-74' : 'g-pl-24 g-lg-pl-34'
              }`}>
              Period-on-Period % Growth{' '}
              {rowData.postfix && <i className={classes.titlePostfix}> {rowData.postfix}</i>}
            </div>
          </td>
          {showSparklines && <td aria-label="trend_placeholder" />}
          {rowData.values.map((col, index) => {
            return (
              <td
                key={col.date_time_f}
                className={`${col.period_on_percent_growth_class} text-center`}>
                {col.period_on_percent_growth}
              </td>
            );
          })}
        </tr>
      )}
    </>
  );
}

FactsheetCollapseTableRow.propTypes = {
  rowData: PropTypes.shape({
    title: PropTypes.string,
    postfix: PropTypes.string,
    subtitle: PropTypes.string,
    title_indent_space: PropTypes.number,
    title_unit: PropTypes.string,
    show_growth: PropTypes.bool,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        date_time_f: PropTypes.string,
        period_f: PropTypes.string,
        period_on_percent_growth: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        value_f: PropTypes.string,
      }),
    ),
    is_sectionTitle: PropTypes.bool, // only when it is subtitle (refer to financial's financial ratios)
  }),
  showAll: PropTypes.bool,
  showSparklines: PropTypes.bool,
  valuesLength: PropTypes.number,
};

export default FactsheetCollapseTableRow;
