/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';

import { fetchData, setPeriod, setPeriodCols, setPage } from './financialsSlice';
import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';
import { createLoadableComponent } from '../../../utils/Loadable';
import { fetchDefinition } from '../../../components/DefinitionPopup/definitionSlice';

const FinancialsProfitAndLoss = createLoadableComponent(() => import('./FinancialProfitLoss'));
const FinancialCashFlow = createLoadableComponent(() => import('./FinancialCashFlow'));
const FinancialRatios = createLoadableComponent(() => import('./FinancialRatios'));
const FinancialBalanceSheet = createLoadableComponent(() => import('./FinancialBalanceSheet'));
const FinancialFilter = createLoadableComponent(() => import('./FinancialFilter'));
const Notes = createLoadableComponent(() => import('./Notes'));

const { CancelToken } = axios;
const checkDataIsEmpty = (data) => {
  if (!data) {
    return false;
  }

  return Object.keys(data).every((key, index, arr) => {
    if (!Array.isArray(data[key])) return true;

    return data[key].length === 0;
  });
};

function Financials({ counter }) {
  const [isSSR, setIsSSR] = useState(true);
  const source = CancelToken.source();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.financials.data);
  const loading = useSelector((state) => state.financials.loading);
  const error = useSelector((state) => state.financials.err);
  const period = useSelector((state) => state.financials.period);
  const periodCols = useSelector((state) => state.financials.periodCols);
  const page = useSelector((state) => state.financials.page);
  const isEmptyData = useMemo(() => checkDataIsEmpty(data), [data]);
  const location = useLocation();

  let pagination;
  if (data) pagination = data.pagination;

  const PERIODS = {
    fy: 'Full Year',
    quarter: 'Quarterly',
    '1q': '1st Quarter',
    '2q': '2nd Quarter',
    '3q': '3rd Quarter',
    '4q': '4th Quarter',
    half: 'Half Yearly',
    '1h': '1st Half',
    '2h': '2nd Half',
    all: 'All',
  };

  const displayNotes = () => {
    const periodWordings =
      period === 'quarterly' ||
      period === '1q' ||
      period === '2q' ||
      period === '3q' ||
      period === '4q'
        ? ' Half-Yearly'
        : ' Quarterly';
    const periodArray = [];
    let breakYear1;
    let breakYear2;

    if (data && data.balance_sheet_chart_data) {
      for (let i = 0; i < data.balance_sheet_chart_data.length; i++) {
        const periodString = data.balance_sheet_chart_data[i].date_time_f;
        const year = periodString.substr(periodString.length - 4);
        if (!periodArray.includes(year)) {
          periodArray.unshift(year);
        }
      }

      for (let i = 0; i < periodArray.length; i++) {
        if (parseInt(periodArray[i]) - 1 != parseInt(periodArray[i + 1])) {
          breakYear2 = parseInt(periodArray[i]) - 1;
          breakYear1 = parseInt(periodArray[i + 1]) + 1;
          break;
        }
      }
    }

    const notes1 =
      data?.profit_loss_chart?.length > 0 && data.profit_loss_chart.length < periodCols ? (
        <div>
          This company has a maximum number of {data.profit_loss_chart.length}{' '}
          <strong>{PERIODS[period]}</strong> financial results, as the previous financial results
          were released <strong>{periodWordings}</strong>.
        </div>
      ) : (
        ''
      );
    const notes2 =
      breakYear1 && breakYear2 ? (
        <div>
          This company is publishing financial results on a<strong>{periodWordings}</strong> basis
          from {breakYear1} to {breakYear2}.
        </div>
      ) : (
        ''
      );

    if (!notes1 && !notes2) {
      return null;
    }

    return (
      <div className="alert alert-info d-block g-mb-40">
        <div className="row gx-3">
          <div className="col-auto text-muted">
            <i className="fa-light fa-xl fa-circle-info" />
          </div>
          <div className="col">
            <div className="text-muted">{notes1}</div>
            <div className="text-muted">{notes2}</div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    const newPeriod = searchParams.get('period');
    const newCols = searchParams.get('cols');
    const newPage = searchParams.get('page');

    dispatch(setPeriodCols(newCols));
    dispatch(setPeriod(newPeriod));
    dispatch(setPage(newPage));
    dispatch(
      fetchData({
        counter,
        cancelToken: source.token,
        period: newPeriod,
        periodCols: newCols,
        page: newPage,
      }),
    );
    dispatch(fetchDefinition({ cancelToken: source.token, source: 'financials' }));
  }, []);

  useEffect(() => {
    if (data && data.cols_to_show && periodCols !== data.cols_to_show) {
      dispatch(setPeriodCols(data.cols_to_show.toString()));
    }
    if (data && data.financials_period && period !== data.financials_period) {
      dispatch(setPeriod(data.financials_period.toString()));
    }
    if (pagination && pagination.current_page && page !== pagination.current_page) {
      dispatch(setPage(pagination.current_page.toString()));
    }
  }, [data]);

  useEffect(() => {
    const newPeriod = searchParams.get('period');
    const newCols = searchParams.get('cols');
    const newPage = searchParams.get('page');
    let reloadData = false;

    if (newPeriod && period !== newPeriod) {
      dispatch(setPeriod(newPeriod || 'fy'));
      reloadData = true;
    }
    if (newCols && periodCols !== newCols) {
      dispatch(setPeriodCols(newCols));
      reloadData = true;
    }
    if (newPage && page !== newPage) {
      dispatch(setPage(newPage));
      reloadData = true;
    }
    if (reloadData) {
      dispatch(
        fetchData({
          counter,
          cancelToken: source.token,
          period: newPeriod,
          periodCols: newCols,
          page: newPage,
        }),
      );
    }
  }, [location]);

  if (isSSR || loading) {
    return <Loader visible={isSSR || loading} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  if (!data || (data && Object.keys(data).length === 0) || (data && isEmptyData)) {
    return (
      <div className="d-flex justify-content-center w-100 g-height-1000">
        <h6 className="mt-5">- No Financials Available -</h6>
      </div>
    );
  }

  return (
    <>
      <FinancialFilter counter={counter} />
      {displayNotes()}
      <div className="d-flex flex-row align-items-end g-mb-22">
        <h4 className="mb-0 me-2 lh-1">Financial Results</h4>
        <span
          className="g-text-size-14"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(`(${data.stock_info.currency})`),
          }}
        />
      </div>
      {data.profit_loss_chart && data.profit_loss_table && (
        <FinancialsProfitAndLoss
          currency={data.stock_info.currency}
          profitLossChart={data.profit_loss_chart}
          profitLossTable={data.profit_loss_table}
          showSparklines={data.show_sparklines_financials}
          showChart={data.financials_show_chart}
        />
      )}
      <FinancialBalanceSheet
        currency={data.stock_info.currency}
        balanceSheetTableData={data.balance_sheet_table_data}
        balanceSheetChartData={data.balance_sheet_chart_data}
        showSparklines={data.show_sparklines_financials}
        showChart={data.financials_show_chart}
      />
      <FinancialCashFlow
        cashFlowChart={data.cash_flow_chart}
        currency={data.stock_info.currency}
        cashFlowTable={data.cash_flow_table_data}
        relatedAttachments={data.related_attachments}
        showSparklines={data.show_sparklines_financials}
        showChart={data.financials_show_chart}
      />
      {data.financial_ratios_chart && data.financial_ratios_table && (
        <FinancialRatios
          financialRatiosChart={data.financial_ratios_chart}
          financialRatiosTable={data.financial_ratios_table}
          currency={data.stock_info.currency}
          showSparklines={data.show_sparklines_ratios}
          showChart={data.financials_show_chart}
        />
      )}
      <Notes
        additionalReferences={data.additional_references}
        showSparklines={data.show_sparklines_ratios}
      />
    </>
  );
}

Financials.propTypes = {
  counter: PropTypes.string,
};

export default Financials;
