import React from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import CompanyNameLink from '../StockInfoPopup/CompanyNameLink';
import { createLoadableComponent } from '../../utils/Loadable';

const StockInfoPopup = createLoadableComponent(() => import('../StockInfoPopup'));
function CompanyLatestBuybackTable({ data, linkToFactsheet, market }) {
  return (
    <>
      <Table hover className="text-center align-middle table-with-hover-popup">
        <thead className="table-light">
          <tr>
            <th>Date of Announcement</th>
            <th>Date of Effective Change</th>
            <th>Stock Name</th>
            <th>Acquired ['000]</th>
            <th>
              Price{' '}
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data.currency),
                }}
              />
            </th>
            <th>
              Estimated Transaction Value ['000]<sup>a</sup>
            </th>
            {linkToFactsheet && <th>Details</th>}
            {market === 'sgx' && <th>Note</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((stock) => {
            return (
              <tr key={stock.id}>
                <td>{stock.date_of_announcement}</td>
                <td>{stock.date_of_effective_change}</td>
                <td className="text-start">
                  <CompanyNameLink counter={stock.counter} name={stock.name} showStockInfoPopUp />
                </td>
                <td>{stock.volume_transacted}</td>
                <td>{stock.price}</td>
                <td>{stock.value_transacted}</td>
                {linkToFactsheet && (
                  <td>
                    <a href={`/quote/${stock.counter}/company_buyback`} className="text-dark">
                      <i className="fa-thin fa-xl fa-magnifying-glass-plus" />
                    </a>
                  </td>
                )}
                {market === 'sgx' && (
                  <td>
                    {stock.remarks !== '' ? (
                      <span className="hover_popup_container g-pl-5">
                        <i className="fa-thin fa-note fa-xl" role="button" />
                        <div className="hover_popup_content left arrow p-3 g-border-radius-10 border bg-white">
                          <div className="hover_popup_content_inner text-start text-muted g-width-300 g-sm-width-500 g-md-width-700">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(stock.remarks),
                              }}
                            />
                          </div>
                        </div>
                      </span>
                    ) : (
                      '-'
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <StockInfoPopup />
    </>
  );
}

CompanyLatestBuybackTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      counter: PropTypes.string,
      stock_name: PropTypes.string,
      announcement_date: PropTypes.string,
      date_of_effective_change: PropTypes.string,
      volume_transacted: PropTypes.string,
      price: PropTypes.string,
      value_transacted: PropTypes.string,
      remarks: PropTypes.string,
    }),
  ),
  linkToFactsheet: PropTypes.bool,
  market: PropTypes.string,
};

export default CompanyLatestBuybackTable;
