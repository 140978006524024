import React from 'react';
import DOMPurify from 'dompurify';

import FactsheetCollapseTable from '../../../components/FactsheetCollapseTable';

function ForwardEstimatesRatios({ tableData, showSparklines, currency }) {
  return (
    <div className="mb-lg-5 mb-3">
      <h4 className="mb-lg-4 mb-3">
        Forward Estimates - Financial Ratios (
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(currency),
          }}
        />
        )
      </h4>
      <FactsheetCollapseTable
        tableData={tableData}
        showSparklines={showSparklines}
        isConsensus
        tableID="forward_estimates_table_ratios"
      />
    </div>
  );
}

export default ForwardEstimatesRatios;
