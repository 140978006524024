import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SignUpModal from './SignUpModal';

function SignUpMain({ country, checkCaptcha, siteKey }) {
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const showModal = () => {
    setShowSignUpModal(true);
  };

  return (
    <div>
      <button id="signup" className="btn btn-primary" onClick={showModal} type="button">
        Get Started
      </button>

      <SignUpModal
        country={country}
        showModal={showSignUpModal}
        setShowModal={setShowSignUpModal}
        checkCaptcha={checkCaptcha}
        siteKey={siteKey}
      />
    </div>
  );
}

export default SignUpMain;

SignUpMain.propTypes = {
  country: PropTypes.string,
  checkCaptcha: PropTypes.bool,
  siteKey: PropTypes.string,
};
