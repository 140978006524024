import React from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import PropTypes from 'prop-types';
import './worldIndicesPrices.css';
import { getParamCounterId } from '../../utils/utils';

import classes from './worldIndicesPrices.module.css';
import { useDetectMobileScreen } from '../../utils/utils';
import CarouselItem from './CarouselItem';

function WorldIndicesCarousel({ data, dataFeedMode, isSSR }) {
  const isMobile = useDetectMobileScreen();

  const mainOptions = {
    type: 'loop',
    // clones: 0,
    drag: 'free',
    perPage: 6,
    perMove: 1,
    autoScroll: {
      speed: 1,
    },
    pagination: false,
    lazyload: 'nearby',
    autoWidth: 'auto',
    height: isMobile ? '85px' : '105px',
    padding: isMobile && '3rem',
  };

  if (!data) {
    return null;
  }
  return (
    <Splide
      options={mainOptions}
      aria-labelledby="thumbnail-slider-example"
      hasTrack={false}
      id="sic_indexList"
      data-feed_mode={dataFeedMode}>
      <SplideTrack>
        {data.map((row, index) => (
          <SplideSlide key={`splide_${index}_${row.code}`} className="mt-1">
            <a className="text-reset text-decoration-none" target='_blank' href={`/quote/${getParamCounterId(row.code)}`}>
              <CarouselItem
                key={`sic_indexList_${index}_${row.code}`}
                label={row.label}
                code={row.code}
                lastDone={row.last_done_price}
                changeVal={row.change}
                percChange={row.percent_change}
              />
            </a>
          </SplideSlide>
        ))}
      </SplideTrack>

      {
        !isMobile && (
          <div className={`splide__arrows ${classes.arrowWrapper}`}>
            <button
              type="button"
              className={`splide__arrow splide__arrow--prev ${classes.arrowPrev}`}>
              {!isSSR && <i className="fa-light fa-angle-right" />}
            </button>
            <div className={`${classes.arrowDivider}`} />
            <button
              type="button"
              className={`splide__arrow splide__arrow--next ${classes.arrowNext}`}
              style={{ right: '1.4em' }}>
              {!isSSR && <i className="fa-light fa-angle-right" />}
            </button>
          </div>
        )
      }
    </Splide >
  );
}

WorldIndicesCarousel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      lastDone: PropTypes.string,
      changeVal: PropTypes.string,
      percChange: PropTypes.string,
    }),
  ),
  dataFeedMode: PropTypes.string,
  isSSR: PropTypes.bool,
};

export default WorldIndicesCarousel;
