import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Card } from 'react-bootstrap';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import flagSG from 'images/icon-flag-singapore.svg';
import flagMY from 'images/icon-flag-malaysia.svg';
import flagID from 'images/icon-flag-indonesia.svg';
import flagTH from 'images/icon-flag-thailand.svg';
import flagHK from 'images/icon-flag-hongkong.svg';
import flagUS from 'images/icon-flag-us.svg';
import flagAU from 'images/icon-flag-australia.svg';
import {
  calculateMonthlyPriceForPlan,
  getLocalCurrency,
  getCurrentPlanLevel,
  PLAN_LEVELS,
} from '../prices';
import Loader from '../../../components/Loader';
import SignUpModal from '../../../components/SignUp/SignUpModal';
import TrialSignUpResultModal from '../../../components/SignUp/TrialSignUpResultModal';
import {
  membershipChoosePlan,
  membershipChoosePeriod,
  membershipSetPriceType,
  membershipSetMarketType,
  membershipSetPromoCode,
} from '../membershipSlice';
import classes from '../membership.module.css';

const PRO_FREE_TRIAL_DAYS = 9;
const PROMO_FREE_TRIAL_DAYS = 90;
const PREMIUM_FREE_TRIAL_DAYS = 18;

function PlanSelection({ country, checkCaptcha, siteKey }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.membership.data);
  const period = useSelector((state) => state.membership.period);
  const plan = useSelector((state) => state.membership.plan);
  const loading = useSelector((state) => state.membership.loading);
  const webproPlan = country === 'my' ? 'webpro_my_local' : 'webpro';
  const stationPlan = country === 'my' ? 'station_my_local' : 'station';
  const [webproPlanPrice, setWebproPlanPrice] = useState();
  const [stationPlanPrice, setStationPlanPrice] = useState();
  const [currentPlan, setCurrentPlan] = useState(0);
  const [showTrialModal, setShowTrialModal] = useState(false);
  const [trialPromoCode, setTrialPromoCode] = useState();
  const [trialCouponCode, setTrialCouponCode] = useState();
  const [redirectToMembership, setRedirectToMembership] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!data || !data.membership) {
      return;
    }

    const newWebproPrice = calculateMonthlyPriceForPlan(data, webproPlan, period, country);
    if (webproPlanPrice !== newWebproPrice) {
      setWebproPlanPrice(newWebproPrice);
    }

    const newStationPrice = calculateMonthlyPriceForPlan(data, stationPlan, period, country);
    if (newStationPrice !== newWebproPrice) {
      setStationPlanPrice(newStationPrice);
    }

    const newCurrentPlan = getCurrentPlanLevel(data.current_membership);
    if (currentPlan !== newCurrentPlan) {
      setCurrentPlan(newCurrentPlan);
    }
  }, [period, data]);

  const subscribe = (event, newPlan, promoCode = null, couponCode = null) => {
    if (currentPlan === PLAN_LEVELS.loggedOut) {
      setShowTrialModal(true);
      if ((promoCode && promoCode.length > 0) || (couponCode && couponCode.length > 0)) {
        setTrialPromoCode(promoCode);
        setTrialCouponCode(couponCode);
      } else {
        setTrialPromoCode();
        setTrialCouponCode();
        setRedirectToMembership(`/membership/plans/select_plans?plan=${newPlan}&period=${period}`);
      }
    } else if ((promoCode && promoCode.length > 0) || (couponCode && couponCode.length > 0)) {
      setTrialPromoCode(promoCode);
      setTrialCouponCode(couponCode);
      setShowTrialModal(true);
    } else {
      setTrialPromoCode();
      dispatch(membershipChoosePlan(newPlan));
      dispatch(membershipSetPriceType());
      dispatch(membershipSetMarketType());
      dispatch(membershipSetPromoCode(searchParams.get('promoCode')));

      navigate({
        pathname: '/membership/plans/select_plans',
        search: createSearchParams({
          plan,
          period,
          promoCode: searchParams.get('promoCode'),
        }).toString(),
      });
    }
    event.preventDefault();
  };

  const handlePeriodChange = (event, newPeriod) => {
    if (newPeriod && period !== newPeriod) {
      dispatch(membershipChoosePeriod(newPeriod));
    }
    event.preventDefault();
  };

  if (loading) {
    return <Loader visible classes="g-height-800 g-mt-80" />;
  }

  if (!data || !data.membership) {
    return null;
  }

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const promoCode = getCookie('promo_code');
  const promoCodeMOOFEST3MTHS = country === 'sg' && promoCode === 'MOOFEST3MTHS';

  return (
    <>
      <div id="planSelection" />
      <Row>
        <div className="text-center g-mt-60">
          <h1>Pick a Plan That Suits Your Needs</h1>
        </div>
      </Row>
      <Row className="text-center">
        <div className="text-center g-mt-20 g-mb-60">
          <ul
            className={`nav nav-pills justify-content-center d-inline-block border ${classes.planPeriodSwitch}`}>
            <li className="nav-item d-inline-block">
              <button
                type="button"
                className={`nav-link ${period === 6 ? 'active bg-primary' : ''} ${
                  classes.planPeriodSwitchBtn
                }`}
                aria-current="page"
                onClick={(e) => handlePeriodChange(e, 6)}>
                Half Yearly
              </button>
            </li>
            <li className="nav-item d-inline-block">
              <button
                type="button"
                className={`nav-link ${period === 12 ? 'active bg-primary' : ''} ${
                  classes.planPeriodSwitchBtn
                }`}
                onClick={(e) => handlePeriodChange(e, 12)}>
                Annually
              </button>
            </li>
          </ul>
        </div>
      </Row>

      <div className="row d-flex justify-content-center">
        <div className="col-md-4 col-sm-12 p-3">
          <Card className="shadow text-center h-100">
            <Card.Body>
              <div className="g-mt-60">
                <h2 className="g-mb-34">Free</h2>
                <h2 className="g-mt-5">{getLocalCurrency(country)}0</h2>
                <small className="text-muted">per month</small>
              </div>
              {currentPlan < PLAN_LEVELS.pro && (
                <button
                  disabled
                  type="button"
                  className={`g-lg-mr-13 btn btn-outline-primary btn-lg d-inline-block w-75 ${
                    currentPlan < PLAN_LEVELS.trial ? 'g-mt-90 g-mb-35' : 'g-mt-30 g-mb-11'
                  }`}>
                  <h5 className="mb-0">Current Plan</h5>
                </button>
              )}
              {currentPlan > PLAN_LEVELS.trial && (
                <div className={classes.nonCurrentPlanBottomMargin} />
              )}
              <MarketsSection />
              <KeyFeaturesSection />
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4 col-sm-12 p-3">
          <Card className="shadow text-center h-100">
            <Card.Body>
              <div className="g-mt-60">
                <h2 className="mb-0">Pro</h2>
                <div className="mb-2">
                  <small className="text-muted font">(Formerly known as WebPro)</small>
                </div>

                <h2 className="g-mt-5">
                  <span className="g-text-size-16 align-middle">From </span>
                  {getLocalCurrency(country)}
                  {webproPlanPrice}
                </h2>
                <small className="text-muted">per month</small>

                {currentPlan < PLAN_LEVELS.trial && (
                  <div className="g-mt-20">
                    <div className="text-bg-dark d-inline-block py-2 px-3 g-border-radius-20">
                      {promoCodeMOOFEST3MTHS ? (
                        <strike>{PRO_FREE_TRIAL_DAYS} days free trial</strike>
                      ) : (
                        `${PRO_FREE_TRIAL_DAYS} days free trial`
                      )}
                    </div>
                  </div>
                )}

                {currentPlan < PLAN_LEVELS.trial && promoCodeMOOFEST3MTHS && (
                  <div className="g-mt-20">
                    <div className="text-bg-dark d-inline-block py-2 px-3 g-border-radius-20">
                      {PROMO_FREE_TRIAL_DAYS} days free trial
                    </div>
                  </div>
                )}
              </div>

              {currentPlan < PLAN_LEVELS.trial && (
                <>
                  <button
                    type="button"
                    className="g-lg-mr-13 btn btn-primary d-inline-block btn-lg g-mt-30 w-75"
                    onClick={(e) => {
                      subscribe(
                        e,
                        webproPlan,
                        promoCodeMOOFEST3MTHS ? '' : `trial_${webproPlan}`,
                        promoCodeMOOFEST3MTHS ? 'MOOFEST3MTHS' : '',
                      );
                    }}>
                    <h5 className="mb-0">Begin Your Free Journey</h5>
                  </button>
                  <div className="g-mt-11">
                    <a
                      href="#"
                      onClick={(e) => {
                        subscribe(e, webproPlan);
                      }}>
                      Skip Trial and Subscribe Now
                    </a>
                  </div>
                </>
              )}

              {currentPlan < PLAN_LEVELS.pro && currentPlan >= PLAN_LEVELS.trial && (
                <button
                  type="button"
                  onClick={(e) => {
                    subscribe(e, webproPlan);
                  }}
                  className="g-lg-mr-13 btn btn-primary d-inline-block btn-lg g-mt-30 w-75 g-mb-11">
                  <h5 className="mb-0">Subscribe</h5>
                </button>
              )}

              {currentPlan === PLAN_LEVELS.pro && (
                <>
                  <button
                    disabled
                    type="button"
                    className={`g-lg-mr-13 btn btn-outline-primary d-inline-block btn-lg w-75 ${
                      currentPlan < PLAN_LEVELS.pro ? 'g-mt-90 g-mb-35' : 'g-mt-15 g-mb-9'
                    }`}>
                    <h5 className="mb-0">Current Plan</h5>
                  </button>

                  <div>
                    <a
                      href="#"
                      onClick={(e) => {
                        subscribe(e, webproPlan);
                      }}>
                      Renew membership
                    </a>
                  </div>
                </>
              )}
              {currentPlan > PLAN_LEVELS.pro && (
                <div className={classes.nonCurrentPlanBottomMargin} />
              )}
              <MarketsSection />
              <KeyFeaturesSection />
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4 col-sm-12 p-3">
          <Card className="shadow text-center h-100">
            <Card.Body>
              <div className="g-mt-60">
                <h2 className="mb-0">Premium</h2>
                <div className="mb-2">
                  <small className="text-muted font">(Formerly known as Station)</small>
                </div>
                <h2 className="g-mt-5">
                  <span className="g-text-size-16 align-middle">From </span>
                  {getLocalCurrency(country)}
                  {stationPlanPrice}
                </h2>
                <small className="text-muted">per month</small>

                {currentPlan < PLAN_LEVELS.trial && (
                  <div className="g-mt-20">
                    <div className="text-bg-dark d-inline-block py-2 px-3 g-border-radius-20">
                      {PREMIUM_FREE_TRIAL_DAYS} days free trial
                    </div>
                  </div>
                )}
              </div>
              {currentPlan < PLAN_LEVELS.trial && (
                <>
                  <button
                    type="button"
                    className="g-lg-mr-13 btn btn-primary d-inline-block btn-lg g-mt-30 w-75"
                    onClick={(e) => {
                      subscribe(e, stationPlan, `trial_${stationPlan}`);
                    }}>
                    <h5 className="mb-0">Begin Your Free Journey</h5>
                  </button>
                  <div className="g-mt-11">
                    <a
                      href="#"
                      onClick={(e) => {
                        subscribe(e, stationPlan);
                      }}>
                      Skip Trial and Subscribe Now
                    </a>
                  </div>
                </>
              )}

              {currentPlan < PLAN_LEVELS.premium && currentPlan >= PLAN_LEVELS.trial && (
                <button
                  type="button"
                  onClick={(e) => {
                    subscribe(e, stationPlan);
                  }}
                  className="g-lg-mr-13 btn btn-primary d-inline-block btn-lg g-mt-30 w-75 g-mb-11">
                  <h5 className="mb-0">Subscribe</h5>
                </button>
              )}

              {currentPlan === PLAN_LEVELS.premium && (
                <>
                  <button
                    disabled
                    type="button"
                    className={`g-lg-mr-13 btn btn-outline-primary d-inline-block btn-lg w-75 ${
                      currentPlan < PLAN_LEVELS.premium ? 'g-mt-90 g-mb-35' : 'g-mt-15 g-mb-9'
                    }`}>
                    <h5 className="mb-0">Current Plan</h5>
                  </button>
                  <div>
                    <a
                      href="#"
                      onClick={(e) => {
                        subscribe(e, stationPlan);
                      }}>
                      Renew Membership
                    </a>
                  </div>
                </>
              )}
              {currentPlan > PLAN_LEVELS.premium && (
                <div className={classes.nonCurrentPlanBottomMargin} />
              )}
              <MarketsSection includeDerivatives />
              <KeyFeaturesSection isPremium />
            </Card.Body>
          </Card>
        </div>
      </div>

      {currentPlan < PLAN_LEVELS.trial && currentPlan === PLAN_LEVELS.loggedOut && (
        <SignUpModal
          country={country}
          showModal={showTrialModal}
          setShowModal={setShowTrialModal}
          promoCode={trialPromoCode}
          redirectToMembership={redirectToMembership}
          checkCaptcha={checkCaptcha}
          siteKey={siteKey}
        />
      )}

      {currentPlan < PLAN_LEVELS.trial && currentPlan !== PLAN_LEVELS.loggedOut && (
        <TrialSignUpResultModal
          showModal={showTrialModal}
          setShowModal={setShowTrialModal}
          country={country}
          trialDays={
            promoCodeMOOFEST3MTHS
              ? PROMO_FREE_TRIAL_DAYS
              : trialPromoCode && trialPromoCode.includes(webproPlan)
              ? PRO_FREE_TRIAL_DAYS
              : PREMIUM_FREE_TRIAL_DAYS
          }
          promoCode={promoCodeMOOFEST3MTHS ? '' : trialPromoCode}
          couponCode={promoCodeMOOFEST3MTHS ? trialCouponCode : ''}
        />
      )}
    </>
  );
}

export default PlanSelection;

PlanSelection.propTypes = {
  country: PropTypes.string,
  checkCaptcha: PropTypes.bool,
  siteKey: PropTypes.string,
};

function MarketsSection({ includeDerivatives }) {
  return (
    <>
      <div className="row d-flex justify-content-center g-mt-40">
        <div className="separator g-mb-20">
          <strong>Equity</strong>
        </div>
        <div className="col-3 px-1 py-2">
          <img src={flagSG} className="icon g-width-40" alt="Singapore" />
          <div>Singapore</div>
        </div>
        <div className="col-3 px-1 py-2">
          <img src={flagMY} className="icon g-width-40" alt="Malaysia" />
          <div>Malaysia</div>
        </div>
        <div className="col-3 px-1 py-2">
          <img src={flagID} className="icon g-width-40" alt="Indonesia" />
          <div>Indonesia</div>
        </div>
        <div className="col-3 px-1 py-2">
          <img src={flagTH} className="icon g-width-40" alt="Thailand" />
          <div>Thailand</div>
        </div>
        <div className="col-3 px-1 py-2">
          <img src={flagHK} className="icon g-width-40" alt="Hong Kong" />
          <div>Hong Kong</div>
        </div>
        <div className="col-3 px-1 py-2">
          <img src={flagUS} className="icon g-width-40" alt="US" />
          <div>US</div>
        </div>
        <div className="col-3 px-1 py-2">
          <img src={flagAU} className="icon g-width-40" alt="Australia" />
          <div>Australia</div>
        </div>
      </div>
      {includeDerivatives && (
        <div className="row d-flex justify-content-center g-mt-40">
          <div className="separator g-mb-20">
            <strong>Derivative</strong>
          </div>
          <div className="col-3 px-1 py-2">
            <img src={flagMY} className="icon g-width-40" alt="Malaysia" />
            <div>Malaysia</div>
          </div>
        </div>
      )}
    </>
  );
}

MarketsSection.propTypes = {
  includeDerivatives: PropTypes.bool,
};

function KeyFeaturesSection({ isPremium }) {
  return (
    <div
      className={`row d-flex justify-content-center g-mt-40 ${
        isPremium ? 'g-mb-20' : classes.nonPremiumBottomMargin
      }`}>
      <div className="separator g-mb-20">
        <strong>Key Features</strong>
      </div>

      {!isPremium && (
        <div className="col-4 p-2">
          <div className="text-muted text-center g-mb-5">
            <i className="fa-regular fa-chart-simple fa-2x" />
          </div>
          <div>Web-based Charting Solution</div>
        </div>
      )}
      {isPremium && (
        <div className="col-4 p-2">
          <div className="text-muted text-center g-mb-5">
            <i className="fa-regular fa-chart-line-up-down fa-2x" />
          </div>
          <div>Dynamic Charting Solution</div>
        </div>
      )}
      <div className="col-4 p-2">
        <div className="text-muted text-center g-mb-5">
          <i className="fa-regular fa-dollar-sign fa-2x" />
        </div>
        <div>Company Financials & Target Price</div>
      </div>
      {!isPremium && (
        <div className="col-4 p-2">
          <div className="text-muted text-center g-mb-5">
            <i className="fa-regular fa-display-chart-up-circle-currency fa-2x" />
          </div>
          <div>Portfolio Manager</div>
        </div>
      )}
      {isPremium && (
        <>
          <div className="col-4 p-2">
            <div className="text-muted text-center g-mb-5">
              <i className="fa-regular fa-laptop-mobile fa-2x" />
            </div>
            <div>Access on Multiple Platforms</div>
          </div>
          <div className="col-4 p-2">
            <div className="text-muted text-center g-mb-5">
              <i className="fa-regular fa-table-columns fa-2x" />
            </div>
            <div>Customizable Layout</div>
          </div>
          <div className="col-4 p-2">
            <div className="text-muted text-center g-mb-5">
              <i className="fa-regular fa-stopwatch fa-2x" />
            </div>
            <div>High Speed Updates</div>
          </div>
          <div className="col-4 p-2">
            <div className="text-muted text-center g-mb-5">
              <i className="fa-regular fa-file-chart-column fa-2x" />
            </div>
            <div>Portfolio Manager</div>
          </div>
        </>
      )}
      <div className="col-4 p-2">
        <div className="text-muted text-center g-mb-5">
          <i className="fa-regular fa-chart-candlestick fa-2x" />
        </div>
        <div>FA & TA Market Screener</div>
      </div>
      <div className="col-4 p-2">
        <div className="text-muted text-center g-mb-5">
          <i className="fa-regular fa-bell fa-2x" />
        </div>
        <div>Stocks & News Alert</div>
      </div>
      <div className="col-4 p-2">
        <div className="text-muted text-center g-mb-5">
          <i className="fa-regular fa-mobile fa-2x" />
        </div>
        <div>ShareInvestor Mobile</div>
      </div>
    </div>
  );
}

KeyFeaturesSection.propTypes = {
  isPremium: PropTypes.bool,
};
