import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import api from '../../../utils/api';
import { createLoadableComponent } from '../../../utils/Loadable';
import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';

const { CancelToken } = axios;
const Rank = createLoadableComponent(() => import('./Rank'));
const Chart = createLoadableComponent(() => import('./Chart'));

function Shareholders({ counter }) {
  const [isSSR, setIsSSR] = useState(true);
  const source = CancelToken.source();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState();

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    const cancelToken = source.token;
    setIsLoading(true);

    api
      .get(`quote/${counter}/shareholders.json?`, { cancelToken })
      .then(({ data }) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, []);

  if (isSSR || isLoading) {
    return <Loader visible={isSSR || isLoading} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <div className="row">
      {data && data.data && data.data.length > 0 && (
        <>
          <div className="col-md-6 col-12">
            <Rank data={data} />
          </div>
          <div className="col-md-6 col-12">
            <Chart data={data} />
          </div>
          <div className="col-12 g-md-mt-60">
            <div className="small text-muted">
              *Remark: Information is only available for companies with market cap of US$50 million
              and above
            </div>
          </div>
        </>
      )}
      {(!data || !data.data || data.data.length === 0) && (
        <div className="d-flex justify-content-center w-100 g-height-1000">
          <h6 className="mt-5">
            - Detailed data on shareholders are not available for this company. -
          </h6>
        </div>
      )}
    </div>
  );
}

Shareholders.propTypes = {
  counter: PropTypes.string,
};

export default Shareholders;
