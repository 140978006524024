import React from 'react';
import { Provider } from 'react-redux';
import store from '../../utils/store';
import SignUpMain from './SignUpMain';

function SignUp(props) {
  return (
    <Provider store={store}>
      <SignUpMain
        country={props.country}
        checkCaptcha={props.checkCaptcha}
        siteKey={props.siteKey}
      />
    </Provider>
  );
}

export default SignUp;
